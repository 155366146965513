import React, { useEffect, useRef, useState } from "react"
import moment from "moment"
import { Dropdown } from "react-bootstrap"
import { faCircleCheck, faEllipsisH, faEye } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import searchi from "./../../assets/images/search.png"
import upload from "../../assets/images/upload.png"

import styles from "./style.module.css"
import { getAllGallery } from "./api"
import commonStyles from "../../common/common.module.css"
import ExpandImage from "./modals/ExpandImage"
import { editGallery } from "../../redux/slices/gallerySlice"
import ChangeStatus from "./modals/ChangeStatus"
import { GALLERY } from "../../utils/gallery"
import {useInfiniteScroll} from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout"
import {downloadExcel} from "../../common/api"
import { ApiRoutes } from "../../utils/api_Routes"


const Gallery = () => {
	const dispatch = useDispatch();
	const [gallery, setGallery]=useState();
	const [showViewMoreModal, setViewMoreModal] = useState(false);
	const [showChangeStatusModal, setChangeStatusModal] = useState(false);
	const [hasMore, setHasMore]=useState(true);
	const [page, setPage]=useState(1);
	const [loading, setLoading]=useState(true);
	const [search, setSearch]=useState("");
	const elementRef = useRef(null);

	const handleExcelDownload = () => {
		downloadExcel(ApiRoutes.GALLERY.excel)
	}
	useEffect(() => {
		if (showChangeStatusModal === false) {
			setPage(1);
			setHasMore(true);

			setGallery([])
		}
	}, [showChangeStatusModal]);

	const fetchMore=()=>{
		setLoading(true);
		getAllGallery({page, limit:10}, search).then((res)=>{
			setLoading(false);
			const {data}=res;
			if(data.length===0)
			{
				setHasMore(false)
			}
			else{
				if(page===1)
				{
					setGallery(data);
				}
				else{
					setGallery(prev=>[...prev, ...data])
				}
				setPage(prev=>prev+1)
			}
		})
	}

	useInfiniteScroll(gallery, elementRef, hasMore, fetchMore);

	const handleViewMoreClose=()=>{
		setViewMoreModal(false)
	}
	const handleChangeStatusClose=()=>{
		setChangeStatusModal(false)
	}
	const handleSearchChange=(e)=>{
		setSearch(e.target.value)
	}
	const handleReset=()=>{
		setSearch("")
		setPage(1);
		setHasMore(true);

		setGallery([])

	}
	const handleSearch=()=>{

		setPage(1);
		setHasMore(true);

		setGallery([])
	}

	return (
    
		<Layout>
			<h2>Gallery </h2>
			<div className={styles.OuterTime}>
				{/* <div className="row">
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
						</div> */}
			</div>
			<div className={styles.tableTime}>
				<div className={styles.topTime}>
					<h5>
								Gallery <i className="fas fa-info-circle"></i>
					</h5>
					<div className={styles.topTimeInner}>
						<form className={styles.formGroup} 
							onSubmit={(e)=>{
								e.preventDefault();
								handleSearch()}
							}>
							<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
							<button onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
							<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>

						</form>	
						<button onClick={handleExcelDownload} className={styles.exportButton}>
							<img src={upload} alt="" /> Export
						</button>
					</div>
				</div>
				<div className={styles.Tabstable}>
					<ul>
						<li className={styles.active}>
							<Link >
										All 
							</Link>
						</li>
							
								
					</ul>
					{/* <div className={styles.filters}>
								<div className={styles.Formgroup}>
									<label>Arrange : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
								<div className={styles.Formgroup}>
									<label>Select : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
							</div> */}
				</div>
				<div className={styles.Tbalelayout}>
					<table>
						<tr>
							<th>Image</th>
							<th>Type</th>
							<th>Name</th>
							<th>User</th>
							<th>Created At</th>
							<th>Updated At</th>

							<th>Status</th>
							<th>Action</th>
						</tr>
						{gallery?.map((data, id) => (
							<tr key={id}>
								<td>
									<img src={data.logo} alt="" /> 
								</td>
								<td>
									{data.master.type} 
								</td>
								<td>
									{data.master.name} 

								</td>
								{<td>{data.user.name}</td>}
								<><td>{moment(data?.createdAt).format("MMM DD, YYYY")}</td>
									<td>{moment(data?.updatedAt).format("MMM DD, YYYY")}</td>
									{/* <td><img src={cmlogo} alt="" /><b>Mosciski Inc.</b></td> */}
									<td>
										<label className={data.status===GALLERY.STATUS.REJECTED?styles.rejected: data.status===GALLERY.STATUS.PENDING && styles.pending}>
											{data.status[0].toUpperCase()+data.status.slice(1)}
										</label>
									</td>
									<td>
										<Dropdown className={commonStyles.tddropdown}>
											<Dropdown.Toggle className={styles.dropdownIcon}>
												{/* <a className="me-2"></a> */}
                                                               
												<FontAwesomeIcon
													// className={commonStyles.iconmargin}
													icon={faEllipsisH}
												/>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() => {
														dispatch(editGallery({ image: data.logo}));
														// setShowEditModal(true);
														// navigate(`${RoutesUrl.POSTS}/${post._id}`)
														setViewMoreModal(true)
													}}
												>
													<FontAwesomeIcon
														size="xs"
														className={commonStyles.iconmargin}
														icon={faEye}
													/>
																Expand Image
												</Dropdown.Item>

												<Dropdown.Item
													onClick={() => {
																		 dispatch(editGallery({ id: data._id }));
																		  setChangeStatusModal(true);
													}}
												>
													<FontAwesomeIcon
														size="xs"
																	 className={commonStyles.iconmargin}
														icon={faCircleCheck}
													/>
																Change Status
												</Dropdown.Item>
																
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</>
							</tr>
						))
						}
					</table>
					{hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div></div>}
				</div>
			</div>
				
			<ExpandImage showModal={showViewMoreModal}
				handleClose={handleViewMoreClose}/>		
		 <ChangeStatus showModal={showChangeStatusModal} handleClose={handleChangeStatusClose}/>
		 </Layout>
   
	)
}

export default Gallery