import React, { useState } from "react"
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";

import styles from  "../style.module.css"
import commonstyles from "../../../../../common/common.module.css"
import { messages } from "../../../../../utils/messages";
import { AddPlanSchema } from "../validations";
import { addBoostPlan } from "../api";
import { SUBSCRIPTIONS } from "../../../../../utils/subscriptions";
import { BOOST } from "../../../../../utils/boost";
const AddBoostPlan = ({ showModal, handleClose}) => {
	const [uploading, setUploading] = useState(false);
	const AddBoostPlanFormik = useFormik({
		validationSchema:AddPlanSchema,
		initialValues: {
			type:"",
			duration:"",
			durationType:"",
			price:""
            
		},

		onSubmit: (values) => {
			
			// const payload={link:values.link,  files, description:values.description}
			toast.promise(
				addBoostPlan(values),
				{
					pending: {
						render() {
							return messages.MASTER.ADDING
						},
					},
					success: {
						render() {
							AddBoostPlanFormik.resetForm();
							handleClose();
							setUploading(false);
							return messages.MASTER.ADDED
						},
					},
					error: {
						render() {
							setUploading(false);
							return messages.MASTER.FAILED
						},
					},
				}
			);
			
		
		},
	});
	const handleAddPost=(e)=>{
		e.preventDefault();
		AddBoostPlanFormik.submitForm();

	}

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
				AddBoostPlanFormik.resetForm();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
                Add Boost Plan
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddPost}>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Type</Form.Label>
								<Form.Control
									name={BOOST.FORMIK.TYPE}
									value={AddBoostPlanFormik.values.type}
									as="select"
									onChange={AddBoostPlanFormik.handleChange}

									
								>
									<option value={""} key={0}>
                    Select
									</option>
									<option value={BOOST.TYPES.ADVERTISEMENT} key={1}>
                    Advertisement
									</option>
									<option value={BOOST.TYPES.CONTRACT} key={2}>
                    Contract
									</option>
									<option value={BOOST.TYPES.JOB} key={3}>
                    Job
									</option>
									<option value={BOOST.TYPES.POST} key={4}>
                    Post
									</option>
									<option value={BOOST.TYPES.PROFILE} key={5}>
                    Profile
									</option>
								
								</Form.Control>
							</Form.Group>
							{AddBoostPlanFormik.errors.type &&
                                AddBoostPlanFormik.touched.type ? (
									<span className={commonstyles.error}>
										{AddBoostPlanFormik.errors.type}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Duration</Form.Label>
								<Form.Control
									type="number"
									placeholder="Duration"
									value={AddBoostPlanFormik.values.duration}
									name={BOOST.FORMIK.DURATION}
									onChange={AddBoostPlanFormik.handleChange}
								/>
							</Form.Group>
							{AddBoostPlanFormik.errors.duration&&
                        AddBoostPlanFormik.touched.duration ? (
									<span className={commonstyles.error}>
										{ AddBoostPlanFormik.errors.duration}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Duration unit</Form.Label>
								<Form.Control
									name={BOOST.FORMIK.DURATION_TYPE}
									value={AddBoostPlanFormik.values.durationType}
									as="select"
									onChange={AddBoostPlanFormik.handleChange}

									
								>
									<option value={""} key={0}>
                    Select
									</option>
									<option value={BOOST.DURATION_TYPE.DAY} key={1}>
                    Day
									</option>
									<option value={BOOST.DURATION_TYPE.MONTH} key={2}>
                    Month
									</option>
									<option value={BOOST.DURATION_TYPE.WEEK} key={3}>
                    Week
									</option>
									<option value={BOOST.DURATION_TYPE.YEAR} key={4}>
                    Year
									</option>
								
								
								</Form.Control>
							</Form.Group>
							{AddBoostPlanFormik.errors.durationType &&
                                AddBoostPlanFormik.touched.durationType ? (
									<span className={commonstyles.error}>
										{AddBoostPlanFormik.errors.durationType}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Price</Form.Label>
								<Form.Control
									type="number"
									placeholder="price"
									value={AddBoostPlanFormik.values.price}
									name={SUBSCRIPTIONS.FORMIK.PRICE}
									onChange={AddBoostPlanFormik.handleChange}
								/>
							</Form.Group>
							{AddBoostPlanFormik.errors.price &&
                        AddBoostPlanFormik.touched.price ? (
									<span className={commonstyles.error}>
										{ AddBoostPlanFormik.errors.price}
									</span>
								) : null}
						</div>	
								
						<Button className={`${styles.buttonss} mt-3`} type="submit" disabled={uploading}>
                    Add
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	)
}

export default AddBoostPlan