import React, { useEffect, useState } from "react"

import styles from "./style.module.css";
// import logo from "../../assets/images/logo.png";
// import search from "../../assets/images/search.png";
// import env from "../../assets/images/env.png";
// import bell from "../../assets/images/bell.png";
// import avatar from "../../assets/images/avatar.png";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
// import graph from "../../assets/images/graph.png"
// import right from "../../assets/images/right.png"
// import payone from "../../assets/images/payone.png"
// import paytwo from "../../assets/images/paytwo.png"
// import paythree from "../../assets/images/paythree.png"
import info from "../../assets/images/info.png"
// import grarow from "../../assets/images/grarow.png"
// import barrow from '../../assets/images/rarrow1.png'
// import blarow from "../../assets/images/blarow.png"
import { getDashboard, getDashboardGraph } from "./api";
import Area from "../../Components/Graph/AreaGraph";
import { KPI } from "../../utils/kpi";
import Graph from "../../Components/Graph"


function Kpi() {


	const [dashboardData, setDashboardData] = useState();
	const [loading, setLoading]=useState(true)
	const [graphData, setGraphData]=useState({
		businessesGraphData:null,
		usersGraphData:null,
		boostGraphData:null
	})


	useEffect(() => {
		Promise.all([getDashboard(), getDashboardGraph(KPI.GRAPH_TYPES.BUSINESS), getDashboardGraph(KPI.GRAPH_TYPES.USERS), getDashboardGraph("boosts")]).then((res)=>{
			setDashboardData(res[0].data);
			console.log(res[3].data, "efdewf")
			setGraphData({businessesGraphData:res[1].data.businesses, usersGraphData:res[2].data.users, boostGraphData:res[3].data})
			setLoading(false)
		})

	}, [])


	return (
		<div className={styles.pageOuter}>
			<Topbar/>
			<div className={styles.pageContainer}>
				<div className={styles.leftContainer}>
					<Sidebar/>
				</div>
				<div className={styles.rightContainer}>
					<h2>KPI Dashboard</h2>
					{/* <div className={styles.OuterTime}>
						<div className='row'>
							<div className='col-md-4'>
								<div className={`${styles.firstTimesheet} ${styles.timeShetet}`}>
									<span><img src={payone} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Timesheets</h6>
										<h5>{dashboardData?.timesheets} <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className={`${styles.secTimesheet} ${styles.timeShetet}`}>
									<span><img src={paytwo} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Expenses</h6>
										<h5>{dashboardData?.expenses} <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className={`${styles.thirdTimesheet} ${styles.timeShetet}`}>
									<span><img src={paythree} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Invoices</h6>
										<h5>{dashboardData?.invoices} <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					{!loading?<>
						<div className={styles.OuterTime}>
							<div className='row'>
								<div className='col-md-2'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Payments Received<img src={info} alt="" /> </h6>
										
										</div>
										<h5>${dashboardData?.paymentReceived}</h5>
										{/* <div className={styles.revenueBoxFoter}>
										<h4>+${dashboardData?.revenue.lastMonthRevenue} <span>from last month</span></h4>
										<span>More <img src={blarow} alt="" /></span>
									</div> */}
                  
									</div>
								</div>
								<div className='col-md-2'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Approved Businesses<img src={info} alt="" /> </h6>
											{/* {dashboardData?.users.userPercentageChange<0?<span className={styles.loss}><img src={barrow} alt="" /> {dashboardData?.users.userPercentageChange}%</span>:<span className={styles.profit}><img src={grarow} alt="" /> {dashboardData?.users.userPercentageChange}%</span>} */}
										</div>
										<h5>{dashboardData?.approvedBusinesses}</h5>
										{/* <div className={styles.revenueBoxFoter}>
										<h4>+{dashboardData?.users.lastMonthUsers} <span>from last month</span></h4>
										<span>More <img src={blarow} alt="" /></span>
									</div> */}
                  
									</div>
								</div>
								<div className='col-md-2'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Professionals Working <img src={info} alt="" /> </h6>
											{/* {
										dashboardData?.works.workPercentageChange<0?<span 
										className={styles.loss}><img src={barrow} alt="" />
										 {dashboardData?.works.workPercentageChange}%</span>:
										 <span className={styles.profit}><img src={grarow} alt="" /> 
										 {dashboardData?.works.workPercentageChange}%</span>} */}
										
										</div>
										<h5>{dashboardData?.professionalsWorking}</h5>
										{/* <div className={styles.revenueBoxFoter}>
										<h4>+{dashboardData?.works.lastMonthWorkCount} <span>from last month</span></h4>
										<span>More <img src={blarow} alt="" /></span>
									</div> */}
                  
									</div>
								</div>
								<div className='col-md-2'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Professional Available <img src={info} alt="" /> </h6>
											{/* {dashboardData?.posts.totalPostCount<0?<span className={styles.loss}><img src={barrow} alt="" /> {dashboardData?.posts.totalPostCount}%</span>:<span className={styles.profit}><img src={grarow} alt="" /> {dashboardData?.posts.totalPostCount}%</span>} */}
										
										</div>
										<h5>{dashboardData?.professionalsAvailable
										}</h5>
										{/* <div className={styles.revenueBoxFoter}>
										<h4>+{dashboardData?.posts.lastMonthPostCount} <span>from last month</span></h4>
										<span>More <img src={blarow} alt="" /></span>
									</div> */}
                  
									</div>
								
								</div>
								<div className='col-md-2'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Open Work <img src={info} alt="" /> </h6>
											{/* {dashboardData?.posts.totalPostCount<0?<span className={styles.loss}><img src={barrow} alt="" /> {dashboardData?.posts.totalPostCount}%</span>:<span className={styles.profit}><img src={grarow} alt="" /> {dashboardData?.posts.totalPostCount}%</span>} */}
										
										</div>
										<h5>{dashboardData?.openWork
										}</h5>
										{/* <div className={styles.revenueBoxFoter}>
										<h4>+{dashboardData?.posts.lastMonthPostCount} <span>from last month</span></h4>
										<span>More <img src={blarow} alt="" /></span>
									</div> */}
                  
									</div>
								
								</div>
							</div>
							<div className={styles.graphPage}>
								{graphData.usersGraphData&&<Area type={"Users"} series={graphData.usersGraphData} seriesName={"users"}/>}	
							</div>
							<div className={styles.graphPage}>
								{graphData.boostGraphData&&<Area type={"Businesses"} series={graphData.businessesGraphData} seriesName={"Businesses"}/>}	
							</div>
							<div className={styles.graphPage}>
								{graphData.usersGraphData&&<Area type={"Businesses"} series={graphData.businessesGraphData} seriesName={"Businesses"}/>}	
							</div>
							<div className={styles.graphPage}>
								{graphData.boostGraphData&&<Graph type={"Boost"} series={graphData.boostGraphData}/>}
							</div>
					
						</div></>:(
						<div className="d-flex justify-content-center">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
				</div>
			</div></div>
	)

    
}

export default Kpi