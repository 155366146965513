import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import { GRAPH } from "../../utils/graph";


const Index = ({series, type}) => {
	const [calculatedSeries, setCalculatedSeries] = useState();
	useEffect(() => {
		const newArray=[]
		Object.keys(series).map((key)=>{
			const seriesItem={};
			seriesItem.name=key;
			let dataArray=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
           
			series[key].map((obj)=>{
				if(type==="Revenue"){
					dataArray[obj.monthCode]=obj.revenue}
				else if(type==="Count"||type==="Timesheets"||type=== "Post Count"||type==="Work Count"||type==="Boost")
				{
					dataArray[obj.monthCode]=obj.count}  
			}
			)
			seriesItem.data=dataArray;
			newArray.push(seriesItem)
		})
    
        
		setCalculatedSeries(newArray)
	}, [series])

	const options={
      
        
		chart: {
			height: 500,
			type: "line",
			zoom: {
				enabled: false
			}
          
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: "smooth"
		},
		title: {
			text: type,
			align: "left"
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"], 
				opacity: 0.5
			},
		},
		xaxis: {
			categories: GRAPH.MONTH_ARRAY,
		}
	}
	return (
		<div>
			<div id="chart">
				{ calculatedSeries&&<ReactApexChart options={options} series={calculatedSeries} type="line" height={500} />}
			</div>
			<div id="html-dist"></div>
		</div>
	)
}

export default Index