import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";

export const getAllGallery = (paginationPayload, search) => {
	return axios.get(ApiRoutes.GALLERY.getAll, {
		params: {...paginationPayload, search},
	});
};
export const getSpecifiedImage=(id)=>{
	return axios.get(`${ApiRoutes.GALLERY.getAll}/${id}`)
}
export const changeStatus=(status, id)=>{
	return axios.put(`${ApiRoutes.GALLERY.getAll}/${id}`, {status})

}