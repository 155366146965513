import { createSlice } from "@reduxjs/toolkit";

export const reviewSlice = createSlice({
	name: "reviews",
	initialState: {id:""},
	reducers: {
		editReviews: (state, action) => {
			state.id=action.payload.id

		},
	}});

// this is for dispatch
export const { editReviews } = reviewSlice.actions;

// this is for configureStore
export default  reviewSlice.reducer;