import React from "react"
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import styles from "../style.module.css";
import { changeCheckListStatus } from "../api";
import { messages } from "../../../utils/messages";

const ChangeStatusCheckList = ({ showModal, handleClose, userIds, type }) => {

	
	const handleChangeStatus=(status)=>{
		toast.promise(
			changeCheckListStatus(userIds, type, status),
			{
				pending: {
					render() {
						return messages.USER.REMOVE
					},
				},
				success: {
					render() {
						handleClose();

						return messages.USER.REMOVED
					},
				},
				error: {
					render() {
						return messages.USER.FAILED
					},
				},
			}
		);
        
        
	}
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Remove User</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h5>Are you sure you want to Change Status Of Selected Users?</h5>
					<Form >
						<div className={styles.spacebetween}>
							<Button
								onClick={()=>handleChangeStatus(true)}
								className={`${styles.statusbuttons} mt-3`}
							>
                Activate
							</Button>
							<Button
								onClick={()=>handleChangeStatus(false)}

								className={`${styles.statusbuttons} mt-3`}
							>
                Deactivate
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	)
}

export default ChangeStatusCheckList