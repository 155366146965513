import * as Yup from "yup";
import { VALIDATIONS } from "../../../utils/yup_validations";
const TooltipSchema = Yup.object()
	.shape({
		text:Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
		name:Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
		labelId:Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
		
		
	})
export {
	TooltipSchema
}