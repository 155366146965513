export const VALIDATION_MESSAGES={
	AUTH:{
		invalidEmail: "Invalid Email",
		login: "Login",
		password: "Password",
		passwordRequired: "Password must be 7 characters at minimum",
		passwordValidation:
    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
		required: "This field cannot be empty",
		somethingWentWrong: "Something Went Wrong"
	}
}