import { axios } from "../../../lib/axios"
import { ApiRoutes } from "../../../utils/api_Routes"
// import { POSTS } from "../../../utils/posts"

export const getAllBoost=( pagination, search, selectedKey, order)=>{
	const param={...pagination, search, selectedKeys:selectedKey, orderBy:"createdAt", order}
	return axios.get(ApiRoutes.BOOST.GET_ALL, {params:param})
}
// export const changeStatus=(status, id)=>{
// 	return axios.patch(`${ApiRoutes.POSTS.getAll}/${id}`, {status})

// }
// export const getSpecifiedPost=(id)=>{
// 	return axios.get(`${ApiRoutes.POSTS.getAll}/${id}`)

// }

// export const addAdminPost=(payload)=>{
// 	return axios.post(`${ApiRoutes.POSTS.getAll}`, payload)
// }