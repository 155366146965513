import React, { useState } from "react"
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";

import styles from  "../style.module.css"
import commonstyles from "../../../common/common.module.css"
import { messages } from "../../../utils/messages";
import { AddPostSchema } from "../validations";
import { uploadFiles } from "../../../common/api";
import { addAdminPost } from "../api";
import { POSTS } from "../../../utils/posts";

const AddPost = ({ showModal, handleClose}) => {
	const [uploading, setUploading] = useState(false);
	const addPostFormik = useFormik({
		validationSchema:AddPostSchema,
		initialValues: {
			description: "",
			link:"",
			file:[]
            
		},

		onSubmit: (values) => {
			setUploading(true);
			uploadFiles(values.file).then((res)=>{
				const {files}=res.data;
				// const payload={link:values.link,  files, description:values.description}
				const payload=values.link ? {link:values.link,  files, description:values.description}: {files, description: values.description}
				toast.promise(
					addAdminPost(payload),
					{
						pending: {
							render() {
								return messages.MASTER.ADDING
							},
						},
						success: {
							render() {
								addPostFormik.resetForm();
								handleClose();
								setUploading(false);
								return messages.MASTER.ADDED
							},
						},
						error: {
							render() {
								setUploading(false);
								return messages.MASTER.FAILED
							},
						},
					}
				);
            
			}).catch(err=>{
				setUploading(false);
				toast.error(messages.MASTER.IMAGE_UPLOAD_FAILED);
			})
			
		
		},
	});
	const handleAddPost=(e)=>{
		e.preventDefault();
		addPostFormik.submitForm();

	}

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
				addPostFormik.resetForm();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
                Add Admin Post
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddPost}>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Description</Form.Label>
								<Form.Control
									type="text"
									placeholder="Description"
									value={addPostFormik.values.description}
									name={POSTS.ADD.DESCRIPTION}
									onChange={addPostFormik.handleChange}
								/>
							</Form.Group>
							{addPostFormik.errors.description &&
                        addPostFormik.touched.description ? (
									<span className={commonstyles.error}>
										{ addPostFormik.errors.description}
									</span>
								) : null}
						</div>	
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Link</Form.Label>
								<Form.Control
									type="text"
									placeholder="Link"
									value={addPostFormik.values.link}
									name={POSTS.ADD.LINK}
									onChange={addPostFormik.handleChange}
								/>
							</Form.Group>
							{addPostFormik.errors.link &&
                        addPostFormik.touched.link ? (
									<span className={commonstyles.error}>
										{ addPostFormik.errors.link}
									</span>
								) : null}
						</div>	
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>File</Form.Label>
								<Form.Control
									multiple
									type="file"
									placeholder="File"
									name={POSTS.ADD.FILE}
									onChange={(event) => {
										const filesArray = Array.from(event.currentTarget.files);
										addPostFormik.setFieldValue(POSTS.ADD.FILE, filesArray);
									}}
								/>
							</Form.Group>
							{addPostFormik.errors.file &&
                        addPostFormik.touched.file ? (
									<span className={commonstyles.error}>
										{ addPostFormik.errors.file}
									</span>
								) : null}
						</div>			
						<Button className={`${styles.buttonss} mt-3`} type="submit" disabled={uploading}>
                    Add
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	)
}

export default AddPost