export const WEBSITE_INQUIRIES = {
	TYPE: {
		ALL: "all",
		ANSWERED: "answered",
		UNANSWERED: "unanswered",
	},
	FORMIK: {
		QUESTION: "question",
		REPLY: "reply",
	},
};
