import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import searchi from "../../../../assets/images/search.png"
import {
	faEllipsisH,	
	faPlus,
} from "@fortawesome/free-solid-svg-icons";
// import { useDispatch } from "react-redux";
// import moment from "moment";

import styles from "./style.module.css";
// import upload from "../../assets/images/upload.png";
// import tabpro from "../../../../assets/images/tabpro.png";
import commonStyles from "../../../../common/common.module.css";
import { getAllBoostPlans } from "./api";
// import ChangeStatus from "./modals/ChangeStatus";
// import { RoutesUrl } from "../../../../utils/RoutesUrl";
import { POSTS } from "../../../../utils/posts";
// import AddPost from "./modals/AddPost";
import {useInfiniteScroll} from "../../../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../../../common/Layout";
import AddSubscriptionPlan from "./modals/AddBoostPlan";
// import { downloadExcel } from "../../common/api";
// import { ApiRoutes } from "../../utils/api_Routes";


function Index() {
	// const dispatch = useDispatch();
	const [boostPlans, setBoostPlans] = useState([]);
	const [tab, setTab] = useState(POSTS.STATUS.ALL);
	const [activeTab, setActiveTab] = useState(POSTS.STATUS.ALL);
	// const navigate=useNavigate()
	// const [showChangeStatusModal, setChangeStatusModal] = useState(false);
	const [showAddPostModal, setAddPostModal] = useState(false);
	const [hasMore, setHasMore]=useState(true);
	const [page, setPage]=useState(1);
	const [loading, setLoading]=useState(true);
	const [search, setSearch]=useState("");
	const elementRef = useRef(null);

	// const handleExcelDownload =()=> {
	// 	downloadExcel(ApiRoutes.POSTS.excel, {search: search})
	// }
	const fetchMore=()=>{
		setLoading(true);
		getAllBoostPlans({page, limit:10}, search).then((res)=>{
			setLoading(false);
			const {data}=res;
			console.log(data, "dataaaaaa plams")
			if(data.length===0)
			{
				setHasMore(false)
			}
			else{
				if(page===1){
					setBoostPlans(data);
				}
				else{
					setBoostPlans(prev=>[...prev, ...data])
				}
			}
			setPage(prev=>prev+1)

			setActiveTab(tab)
		})
	}


	useInfiniteScroll(boostPlans, elementRef, hasMore, fetchMore);


	// const handleChangeStatusClose = () => {
	// 	setChangeStatusModal(false);
	// };
	const handleAddPostClose = () => {
		setAddPostModal(false);
	};
	

	useEffect(() => {
		setPage(1);
		setHasMore(true);

		setBoostPlans([])
	}, [tab])
	useEffect(() => {
		if(showAddPostModal===false)
		{
			setPage(1);
			setHasMore(true);

			setBoostPlans([])
		}
	}, [showAddPostModal])
	// useEffect(() => {
	// 	if(showChangeStatusModal===false)
	// 	{
	// 		setPage(1);
	// 		setHasMore(true);

	// 		setBoostPlans([])
	// 	}
	// }, [showChangeStatusModal])
	
	
	const handleActivePanel = (type) => {
		setTab(type);
	};
	const handleSearchChange=(e)=>{
		setSearch(e.target.value)
	}
	const handleReset=()=>{
		setSearch("")
		setPage(1);
		setHasMore(true);

		setBoostPlans([])


	}
	const handleSearch=()=>{

		setPage(1);
		setHasMore(true);

		setBoostPlans([])
	}

	return (
		<Layout>
			<h2>Boost Plans</h2>
			<div className={styles.OuterTime}>
				{/* <div className="row">
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
						</div> */}
			</div>
			<div className={styles.tableTime}>
				<div className={styles.topTime}>
					<h5>
								Boost Plans <i className="fas fa-info-circle"></i>
					</h5>
					<div className={styles.topTimeInner}>
						<form className={styles.formGroup} 
							onSubmit={(e)=>{
								e.preventDefault();
								handleSearch()}
							}>
							<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
							<button onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
							<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>

						</form>
						<button onClick={()=>setAddPostModal(true)}>
							<FontAwesomeIcon icon={faPlus} /> Add
						</button>
						{/* <button onClick={handleExcelDownload} className={styles.exportButton}>
							<img src={upload} alt = ""/> Export
						</button> */}
					</div>
				</div>
				<div className={styles.Tabstable}>
					<ul>
						<li className={activeTab === POSTS.STATUS.ALL && styles.active}>
							<Link onClick={() => handleActivePanel(POSTS.STATUS.ALL)}>
										All Boost Plans
							</Link>
						</li>
								
						{/* <li className={activeTab === POSTS.STATUS.PENDING && styles.active}>
							<Link onClick={() => handleActivePanel(POSTS.STATUS.PENDING)}>
										Pending
							</Link>
						</li>
						<li className={activeTab === POSTS.STATUS.APPROVED&& styles.active}>
							<Link onClick={() => handleActivePanel(POSTS.STATUS.APPROVED)}>
										Approved
							</Link>
						</li>
						<li className={activeTab === POSTS.STATUS.REJECTED&& styles.active}>
							<Link onClick={() => handleActivePanel(POSTS.STATUS.REJECTED)}>
										Rejected
							</Link>
						</li> */}
								
					</ul>
					{/* <div className={styles.filters}>
								<div className={styles.Formgroup}>
									<label>Arrange : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
								<div className={styles.Formgroup}>
									<label>Select : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
							</div> */}
				</div>
				<div className={styles.Tbalelayout}>
					<table>
						<tr>
							<th>Type </th>
							<th>Duration </th>
							<th>Price</th>							
							<th>Action</th>
						</tr>
									
						{
							boostPlans?.map((plan, id) => 
								<tr className="content-row" key={id}>
									
									<td>
										{plan?.type}
												
									</td>
									<td>
										{plan?.duration} {plan?.durationType}
												
									</td>
									<td>
										{plan?.price} $
												
									</td>

									{/* <td className="text-center">{post?.files.length}</td>
									<td className="text-center">
										<label className={post.status===POSTS.STATUS.REJECTED?styles.rejected: post.status===POSTS.STATUS.PENDING && styles.pending}>
											{post.status[0].toUpperCase()+post.status.slice(1)}
										</label>
									</td> */}
									{/* <td><img src={cmlogo} alt="" /><b>Mosciski Inc.</b></td> */}
									{/* <td>{user.isVerified ? "Verified" : "Not Verified"}</td> */}
									{/* <td>
														<label className={!user.status&&styles.inactive}>{user.status ? "Active" : "Not Active"}</label>
														{console.log(user.status)}
													</td> */}
									<td>
										<Dropdown className={commonStyles.tddropdown}>
											<Dropdown.Toggle className={styles.dropdownIcon}>
												{/* <a className="me-2"></a> */}
                                                               
												<FontAwesomeIcon
													// className={commonStyles.iconmargin}
													icon={faEllipsisH}
												/>
											</Dropdown.Toggle>

											{/* <Dropdown.Menu>
												<Dropdown.Item
													onClick={() => {
														// dispatch(editUser({ id: user._id }));
														// setShowEditModal(true);
														navigate(`${RoutesUrl.POSTS}/${post._id}`)
													}}
												>
													<FontAwesomeIcon
														size="xs"
														className={commonStyles.iconmargin}
														icon={faEye}
													/>
																View More
												</Dropdown.Item>

												<Dropdown.Item
													onClick={() => {
																		 dispatch(editPost({ id: post._id }));
																		 setChangeStatusModal(true);
													}}
												>
													<FontAwesomeIcon
														size="xs"
																	 className={commonStyles.iconmargin}
														icon={faCircleCheck}
													/>
																Change Status
												</Dropdown.Item>
																
											</Dropdown.Menu> */}
										</Dropdown>
									</td>
								</tr>
							) 
						}
					
									
					</table>
					{hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div></div>}
							
								
				</div>
						
			</div>	
			<AddSubscriptionPlan
				showModal={showAddPostModal}
				handleClose={handleAddPostClose}
			/>
			{/* <AddPost
				showModal={showAddPostModal}
				handleClose={handleAddPostClose}
			/> */}
		</Layout>
	);
}

export default Index;
