export const POSTS={
	STATUS:{
		ALL:"all",
		APPROVED:"approved",
		REJECTED:"rejected",
		PENDING:"pending"
	},
	ADD:{
		DESCRIPTION:"description",
		LINK:"link",
		FILE:"file"
	},
	TYPE:{
		ADVERTISEMENT:"advertisement"
	}
}