import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import moment from "moment";

import styles from "./style.module.css";
import Sidebar from "../../../common/Sidebar/index";
import Topbar from "../../../common/Topbar";
import dlt from "../../../assets/images/dlt.png"
import companyLogo from "../../../assets/images/companyLogo.png"
import loc from "../../../assets/images/loc.png"
import infogr from "../../../assets/images/infogr.png"
import crtick from "../../../assets/images/crtick.png"
import pnedit from "../../../assets/images/pnedit.png"
import print from "../../../assets/images/print.png"
import dnlod from "../../../assets/images/dnlod.png"
import { getSpecificTimesheet } from "../api";
import ChangeStatus from "../modals/StatusChange";


function ViewMoreTimesheet() {
	const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const { id } = useParams();
	const [timesheetInfo, setTimesheetInfo] = useState();
	const [showChangeStatusModal, setChangeStatusModal] = useState({display:false, status:"rejected"});

	// const date=Date.now()
	// console.log(date.toString())
	const handleChangeStatusClose=()=>{
		setChangeStatusModal({display:false, status:"rejected"})
	}

	useEffect(() => {
		getSpecificTimesheet(id).then((res)=>{
			setTimesheetInfo(res.data);
			console.log(res.data, "data")
		})
	}, [])


	return (
		<div className={styles.pageOuter}>
			<Topbar/>
			<div className={styles.pageContainer}>
				<div className={styles.leftContainer}>
					<Sidebar/>
				</div>
				<div className={styles.rightContainer}>
					<h2>Timesheet Details</h2>
					<div className={styles.baseInfo}>
                       
						<div className='row'>
							{/* <div className='col-md-6'>
								<div className={styles.innerbaseinfo}>
									<div className={styles.leftInfo}>
										<img src={companyLogo} alt=""/>
										<h6>#{timesheetInfo?.work?.workNumber}</h6>
										<label>Hourly Contract</label>
									</div>
									<div className={styles.rightInfo}>
										<h5>{timesheetInfo?.work?.companyDetails?.name}</h5>
										<h5>{timesheetInfo?.companyDetails?.name}</h5>
										<h6><img src={loc} alt=""/>{timesheetInfo?.work?.street}</h6>
										<h6>Rate PerHour : ${timesheetInfo?.work?.rates.baseRate} </h6>
										<ul>
											<li>Start Date: {timesheetInfo?.startDate}</li>
											<li> Est End Date: {timesheetInfo?.endDate}</li>
											<li> Expense Terms : Net 30</li>
										</ul>
									</div>
								</div>
							</div> */}
							<div className='col-md-6'>
								<div className={styles.innerbaseinfo}>
									<div className={styles.leftInfo}>
										<img src={companyLogo} alt=""/>
										<h6>#{timesheetInfo?.work?.workNumber}</h6>
										<label>Hourly Contract</label>
									</div>
									<div className={styles.rightInfo}>
										<h5>{timesheetInfo?.work?.companyDetails?.name}</h5>
										<h5>{timesheetInfo?.companyDetails?.name}</h5>
										<h6><img src={loc} alt=""/>{timesheetInfo?.work?.street}</h6>
										<h6>Rate PerHour : ${timesheetInfo?.work?.rates.baseRate} </h6>
										<ul>
											<li>Start Date: {moment(timesheetInfo?.startDate).format("MMM DD, YYYY")}</li>
											<li> Est End Date: {moment(timesheetInfo?.endDate).format("MMM DD, YYYY")}</li>
											<li> Expense Terms : Net 30</li>
										</ul>
									</div>
								</div>
							</div>
                            
						</div>
						<div className={styles.timeSheetdetils}>
							<div className={styles.timeSheetdetilshead}>
								<h4>Timesheet details  Details <img src={infogr} alt=""/></h4>
								<div className={styles.editBtns}>
									<button>View Signed Timesheet </button>
									<button><img src={dlt} alt=""/> Delete</button>
									<button><img src={crtick} alt=""/> Notify</button>
									<button><img src={pnedit} alt=""/> Edit</button>
									<button><img src={print} alt=""/> Print</button>
									<button><img src={dnlod} alt=""/> Export Data</button>
								</div>
							</div>
							<div className={styles.personalDetials}>
								<div className={styles.topBrd}>
									<div className={styles.innerbrd}><b>Engineers :</b></div>
									<div className={styles.innerbrd}>{timesheetInfo?.user?.name}</div>
									<div className={styles.innerbrd}><b>Monday / Week Start Date</b></div>
								</div>
								<div className={styles.topBrd}>
									<div className={styles.innerbrd}><b>Customer Company Name</b></div>
									<div className={styles.innerbrd}>FGSFSF hfhfbfbh infor</div>
									<div className={styles.innerbrd}>{moment(timesheetInfo?.startDate).format("MMM DD, YYYY")}</div>
								</div>
								{/* <div className={styles.topBrd}>
									<div className={styles.innerbrd}><b>Customer # :</b></div>
									<div className={styles.innerbrd}>47110</div>
								</div> */}
								<div className={styles.topBrd}>
									<div className={styles.innerbrd}><b>Customer Contact : </b></div>
									<div className={styles.innerbrd}>{timesheetInfo?.work?.user?.name}</div>
									<div className={styles.innerbrd}><b>Customer Email : </b></div>
									<div className={styles.innerbrd}>{timesheetInfo?.work?.user?.email}</div>
								</div>
								<div className={styles.topBrd}>
									<div className={styles.innerbrd}><b>Work Number :  </b></div>
									<div className={styles.innerbrd}>#{timesheetInfo?.work?.workNumber}</div>
								</div>
								{/* <div className={styles.topBrd}>
									<div className={styles.innerbrd}><b>End Customer :   </b></div>
									<div className={styles.innerbrd}>#10179800</div>
								</div> */}
							</div>
							<div className={styles.personalDetials}>
								<div className={`${styles.Morefieldss} ${styles.topBrd}`}>
									<div className={styles.innerbrd}><b>Day</b></div>
									<div className={styles.innerbrd}><b>Date</b></div>
									<div className={styles.innerbrd}><b>Hours Worked Per Day</b></div>
									<div className={styles.innerbrd}><b>Sunday / Holidays</b></div>
									<div className={styles.innerbrd}><b>Travel Time Hours</b></div>
									<div className={styles.innerbrd}><b>Total</b></div>
								</div>
								{	weekDays.map((days, i)=><><div className={`${styles.topBrd} ${styles.Morefieldss}`}>
                          
									<div className={styles.innerbrd}><b>{days}</b></div>
									<div className={styles.innerbrd}><b>{moment(timesheetInfo?.timesheetDetails[i]?.workedDate).format("MMM DD, YYYY")}</b></div>
									<div className={styles.innerbrd}><b>{timesheetInfo?.timesheetDetails[i]?.hourPerDay}</b></div>
									<div className={styles.innerbrd}><b></b></div>
									<div className={styles.innerbrd}><b></b></div>
									<div className={styles.innerbrd}><b>{timesheetInfo?.timesheetDetails[i]?.total}</b></div>
								</div>
								<div className={`${styles.topBrd} ${styles.brdColor}`}>
									<div className={styles.innerbrd}>Monday Work Report</div>
									<div className={styles.innerbrd}>AMKGH Smart fill Project Debuging PLC & HMI Programs</div>                           
								</div></>)}
							
							</div>
							<div className={styles.billingDetails}>
								<div className='row'>
									<div className='col-md-6'>
										<div className={styles.billingDeailLeft}>
											<ul>
												<li><span><b>Total Straight Time</b></span> <label><b>{timesheetInfo?.totalStraightTime}</b></label></li>
												<li><span>Total Over Time</span> <label>{timesheetInfo?.overTime}</label></li>
												<li><span>Total Double TIme</span> <label>{timesheetInfo?.doubleTime}</label></li>
												<li><span>Total Travel TIme</span> <label>{timesheetInfo?.totalTravelTime}</label></li>
												<li><span>Total Hours</span> <label>{timesheetInfo?.totalHours}</label></li>
											</ul>
										</div>
									</div>
									<div className='col-md-6'>
										<div className={styles.billingDeailLeft}>
											<ul>
												<li><span><b>Bill Rates</b></span> </li>
												<li><span>Base Rate (per hour)</span> <label>{timesheetInfo?.work?.rates?.baseRate}</label></li>
												<li><span>OT Rate (per hour)</span> <label>{timesheetInfo?.work?.rates?.overTimeRate}</label></li>
												<li><span>DT Rate (per hour)</span> <label>{timesheetInfo?.work?.rates?.doubleTimeRate}</label></li>
												<li><span>Travel Time Rate</span> <label>{timesheetInfo?.work?.travels?.travelTimeRate}</label></li>
												<li><span>Travel Trips Allowed (per month)</span> <label>{timesheetInfo?.work?.travels?.tipAllowed}</label></li>
												<li><span>Travel Hours Per Round Trip </span> <label>{timesheetInfo?.work?.travels?.perRoundTrip===""?"0":timesheetInfo?.work?.travels?.perRoundTrip}</label></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
                           
						</div>
						<div className={styles.addComment}>
							<h4>Admin Comment</h4>
							<div className={styles.frogroup}>
								<textarea placeholder='No Comments found.....'></textarea>
							</div>
						</div>
						<div className={styles.commentBtn}>
							{timesheetInfo?.status==="pending"&&<><button onClick={()=>setChangeStatusModal({status:"approved", display:true})}>Approve</button>
								<button onClick={()=>setChangeStatusModal({status:"rejected", display:true})} className={styles.reject}>Reject</button></>}
							<button>Upload Signed Timesheet</button>
							<button>Print</button>
						</div>
					</div>
               
				</div>
			</div>
			<ChangeStatus
	  showModal={showChangeStatusModal.display}
	  status={showChangeStatusModal.status}
	  handleClose={handleChangeStatusClose}
			/> 
		</div>
	)
}
export default ViewMoreTimesheet