import { createSlice } from "@reduxjs/toolkit";

export const gallerySlice = createSlice({
	name: "gallery",
	initialState: {image:"", id:""},
	reducers: {
		editGallery: (state, action) => {
			state.image=action.payload?.image
			state.id=action.payload?.id

		},
	}});

// this is for dispatch
export const { editGallery } = gallerySlice.actions;

// this is for configureStore
export default  gallerySlice.reducer;