import { axios } from "../../../../../lib/axios"
import { ApiRoutes } from "../../../../../utils/api_Routes"

export const getAllBoostPlans=(pagination, search)=>{
	const param={...pagination, search}
	return axios.get(ApiRoutes.BOOST.GET_ALL_PLANS, {params:param})
}
export const  addBoostPlan=(payload)=>{
	return axios.post(ApiRoutes.BOOST.GET_ALL_PLANS, payload)
}
// export const changeStatus=(status, id)=>{
// 	return axios.patch(`${ApiRoutes.POSTS.getAll}/${id}`, {status})

// }
// export const getSpecifiedPost=(id)=>{
// 	return axios.get(`${ApiRoutes.POSTS.getAll}/${id}`)

// }

// export const addAdminPost=(payload)=>{
// 	return axios.post(`${ApiRoutes.POSTS.getAll}`, payload)
// }