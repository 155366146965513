export const TIMESHEETS = {
	
	STATUS:{
		PENDING:"pending",
		APPROVED:"approved",
		REJECTED:"rejected",
		ALL:"all"

	},

	COLUMNS:{
		USERNAME:"userName",
		 WORKNUMBER:"workNumber", 
		 WORK_UPDATED_AT:"workUpdatedAt", 
		 WORK_CREATED_AT:"workCreatedAt", 
		 WORK_START_DATE:"workStartDate", 
		 WORK_END_DATE:"workEndDate", 
		 WORK_TYPE:"workType", 
		 START_DATE:"startDate", 
		 END_DATE:"endDate", 
		 APPROVED_DATE:"approvedDate"
	},

	DEFAULT_SELECTED_COLUMNS:[ 
		"userName",
		"businessName",
		"workNumber",
		"workType",
	
	],
	WORK_DEFAULT_SELECTED_COLUMNS:["userName",
		"startDate",
		"endDate",
		"status",],

	ALL_COLUMNS:[  "userName",
		"userProfile",
		"businessName",
		"businessProfile",
		"workNumber",
		"workType",
		"workTypeState",
		"workStatus",
		"status",
		"workStartDate",
		"workEndDate",
		"companyName",
		"companyLogo",
		"workCreatedAt",
		"workUpdatedAt"],

	WORK_ALL_COLUMNS:[    
		"approvedDate",
		"startDate",
		"endDate",
		"totalStraightTime",
		"overTime",
		"doubleTime",
		"totalTravelTime",
		"totalHours",
		"ipAddress",
		"status",
		"createdAt",
		"updatedAt",
		"totalAmount",
		"userName",
		"userProfile",
		"workNumber",
		"address",
		"approvedBy",
		"baseRate",
		"overTimeRate",
		"doubleTimeRate",
		"travelTimeAllowed"]
	
};