import React from "react"

import styles from "./style.module.css";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
import tabpro from "../../assets/images/tabpro.png"
import email from "../../assets/images/email.png"
import phone from "../../assets/images/phone.png"
import dots from "../../assets/images/dots.png"
import payone from "../../assets/images/payone.png"
import paytwo from "../../assets/images/paytwo.png"
import paythree from "../../assets/images/paythree.png"
import right from "../../assets/images/right.png"
import accepttwo from "../../assets/images/accepttwo.png"
import acceptone from "../../assets/images/acceptone.png"
import sort from "../../assets/images/sort.png"

function Businesses() {
	return (

		<div className={styles.pageOuter}>
			<Topbar/>
			<div className={styles.pageContainer}>
				<div className={styles.leftContainer}>
					<Sidebar/>
				</div>
				<div className={styles.rightContainer}>
					<h2>Businesses</h2>
					<div className={styles.OuterTime}>
						<div className='row'>
							<div className='col-md-4'>
								<div className={`${styles.firstTimesheet} ${styles.timeShetet}`}>
									<span><img src={payone} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Timesheets Due</h6>
										<h5>86 <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className={`${styles.secTimesheet} ${styles.timeShetet}`}>
									<span><img src={paytwo} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Timesheets Due</h6>
										<h5>86 <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className={`${styles.thirdTimesheet} ${styles.timeShetet}`}>
									<span><img src={paythree} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Timesheets Due</h6>
										<h5>86 <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.topHeaderongoing}>
						<div className={styles.leftshowcase}>
							<h6>Showing 6 Ongoing works </h6>
							<h5>Based your preferences</h5>
						</div>
						<div className={styles.rightshowcase}>
							<span><img src={accepttwo} alt="" /> Fulltime</span>
							<span><img src={acceptone} alt="" /> Contcart</span>
							<div className={styles.FormGroup}>
								<span><img src={sort} alt="" /> </span>
								<select>
									<option>Newest</option>
								</select>
                        
							</div>
                       
						</div>
					</div>
					<div className={styles.tableTime}>
                  
						<div className={styles.Tbalelayout}>
							<table>
								<tr>
									<th>  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/> ID</th>
									<th>Businesses</th>
									<th>Type</th>
									<th>Classification</th>
									<th>Created On</th>
									<th>Contact</th>
									<th>Status</th>
									<th>Action</th>
								</tr>

								<tr>
									<td> <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>  #4566</td>
                                
									<td><span><img src={tabpro} alt="" /></span>
										<div>
                                Mosciski Inc.
											<p>Creative Design Agency</p>
										</div>
									</td>
									<td>FREELANCE</td>
									<td>Service Partner</td>
									<td>12/11/4098</td>
									<td><span><img src={email} alt="" /></span><span><img src={phone} alt="" /></span></td>
									<td><label className={styles.pending}>Pending</label></td>
									<td><img src={dots} alt="" /></td>
								</tr>

								<tr>
									<td> <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>  #4566</td>
                                
									<td><span><img src={tabpro} alt="" /></span>
										<div>
                                Mosciski Inc.
											<p>Creative Design Agency</p>
										</div>
									</td>
									<td>FREELANCE</td>
									<td>Service Partner</td>
									<td>12/11/4098</td>
									<td><span><img src={email} alt="" /></span><span><img src={phone} alt="" /></span></td>
									<td><label >Pending</label></td>
									<td><img src={dots} alt="" /></td>
								</tr>
								<tr>
									<td>  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>  #4566</td>
                                
									<td><span><img src={tabpro} alt="" /></span>
										<div>
                                Mosciski Inc.
											<p>Creative Design Agency</p>
										</div>
									</td>
									<td>FREELANCE</td>
									<td>Service Partner</td>
									<td>12/11/4098</td>
									<td><span><img src={email} alt="" /></span><span><img src={phone} alt="" /></span></td>
									<td><label className={styles.pending}>Pending</label></td>
									<td><img src={dots} alt="" /></td>
								</tr>

								<tr>
									<td> <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>  #4566</td>
                                
									<td><span><img src={tabpro} alt="" /></span>
										<div>
                                Mosciski Inc.
											<p>Creative Design Agency</p>
										</div>
									</td>
									<td>FREELANCE</td>
									<td>Service Partner</td>
									<td>12/11/4098</td>
									<td><span><img src={email} alt="" /></span><span><img src={phone} alt="" /></span></td>
									<td><label >Pending</label></td>
									<td><img src={dots} alt="" /></td>
								</tr>
							</table>
						</div>
                   
					</div>
				</div>
			</div>
		</div> 

	)

    
}
    
export default Businesses