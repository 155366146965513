import * as Yup from "yup";
import { VALIDATIONS } from "../../../utils/yup_validations";
// import { VALIDATIONS } from "../../../utils/yup_validations";


const AddTemplateSchema = Yup.object().shape({
	name: Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
	templateCode:Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
	userType:Yup.string().required("This field is required").min(1, "String cannot be empty"),
	communicationType:Yup.string().required("This field is required").min(1, "String cannot be empty"),

});

const SendEmailSchema=Yup.object().shape({
	email:Yup.string().email().required()
})


export { AddTemplateSchema, SendEmailSchema };