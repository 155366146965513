import * as Yup from "yup"

import { VALIDATION_MESSAGES } from "../../../../utils/validationMessages";
import { VALIDATIONS } from "../../../../utils/yup_validations";

const loginDetailsSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, VALIDATION_MESSAGES.AUTH.passwordRequired)
		.matches(VALIDATIONS.AUTH.validPasswordRegex, VALIDATION_MESSAGES.AUTH.passwordValidation)
		.required(VALIDATION_MESSAGES.AUTH.required),
	email: Yup.string()
		.required(VALIDATION_MESSAGES.AUTH.required)
		.matches(VALIDATIONS.validEmailRegex, VALIDATION_MESSAGES.AUTH.invalidEmail),
});

export  {loginDetailsSchema}