import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";


export const getAllTooltips=(paginationPayload, selectedKey, order)=>{
	return axios.get(ApiRoutes.TOOLTIPS.get_ALL, {params: { ...paginationPayload, selectedKeys:selectedKey, orderBy:"createdAt", order}})
}
export const getSpecificTooltip=(id)=>{
	return axios.get(`${ApiRoutes.TOOLTIPS.get_ALL}/${id}`)
}

export const updateTooltip=(name, labelId, text, id)=>
{
	
	return axios.put(`${ApiRoutes.TOOLTIPS.get_ALL}/${id}`, {name, labelId, text})

}
export const addTooltip=(name, labelId, text)=>{

	return axios.post(ApiRoutes.TOOLTIPS.get_ALL, {name, labelId, text})
	
}