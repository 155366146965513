import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";

export const getProfessionalByType = (type, paginationPayload, search, selectedKeys, orderBy, order, country) => {
	return axios.get(ApiRoutes.USERS.getAll, {
		params: { type, ...paginationPayload, search, selectedKeys, orderBy, order, countryId:country===0?undefined:country },
	});
};
export const getAllCountry=()=>{
	return axios.get(ApiRoutes.USERS.country);
}