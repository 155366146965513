export const INVOICE = {
	
	STATUS:{
		PENDING:"pending",
		APPROVED:"approved",
		REJECTED:"rejected",
		ALL:"all"

	},

	COLUMNS:{
		USERNAME:"userName",
		 WORKNUMBER:"workNumber", 
		 WORK_UPDATED_AT:"workUpdatedAt", 
		 WORK_CREATED_AT:"workCreatedAt", 
		 WORK_START_DATE:"workStartDate", 
		 WORK_END_DATE:"workEndDate", 
		 WORK_TYPE:"workType", 
		 START_DATE:"startDate", 
		 END_DATE:"endDate", 
		 APPROVED_DATE:"approvedDate"
	},

	DEFAULT_SELECTED_COLUMNS:[

		"workId",
		"userName",
		"businessName",
		"workNumber",
		"workType"],
	WORK_DEFAULT_SELECTED_COLUMNS:["userName",
		"userProfile",                                                                                                                                                                                                                                                                                                                                  
		"workNumber",
		"address"],

	ALL_COLUMNS:["userId",
		"workId",
		"businessId",
		"userName",
		"userProfile",
		"businessName",
		"businessProfile",
		"workNumber",
		"workType",
		"workTypeState",
		"workStatus",
		"status",
		"workStartDate",
		"workEndDate",
		"companyName",
		"companyLogo",
		"workCreatedAt",
		"workUpdatedAt"],

	WORK_ALL_COLUMNS:["userName",
		"userProfile",                                                                                                                                                                                                                                                                                                                                  
		"workNumber",
		"address",
		"type",
		"startDate",
		"endDate",
		"dueDate",
		"createdAt",
		"updatedAt",
		"invoiceNumber",
		"poNumber",
		"comment",
		"paymentTerm",
		"expenseDiffrence",
		"additionalDeduction",
		"expenseBookingFee",
		"laborTotal",
		" expenseTotal",
		"total",
		"isPaid",
		"isBusiness",
		"bankName",
		"accountName",
		"routing",
		"accNo",
		"mobileNumber"]
	
};