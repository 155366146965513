import * as Yup from "yup";

import { VALIDATIONS } from "../../../utils/yup_validations";

const AddPostSchema = Yup.object()
	.shape({
		description: Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
		link: Yup.string().url(VALIDATIONS.MESSAGES.LINK),
		file: Yup.array().test("fileType", VALIDATIONS.MESSAGES.FILES, (files) => {
			for (let i = 0; i < files.length; i++) {
				const fileType = files[i].type.split("/")[0];
				if (fileType !== "image" && fileType !== "video") {
					return false;
				}
			}
			return true;
		}),
	})
	.test("link-or-file", VALIDATIONS.MESSAGES.LINK_OR_FILE, function (values) {
		const { link, file } = values;
		if (!link && !(file && file.length > 0)) {
			return this.createError({
				path: "file",
				message: VALIDATIONS.MESSAGES.LINK_OR_FILE,
			});
		}
		return true;
	});


export { AddPostSchema };
