import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: "users",
	initialState: {id:""},
	reducers: {
		editUser: (state, action) => {
			state.id=action.payload.id
		},
	}});

// this is for dispatch
export const { editUser } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;