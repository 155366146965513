import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";
import { WORK } from "../../../utils/work";

export const getWorkByStatus = (status, paginationPayload, search, selectedKey, order, country) => {

	const params={
		...paginationPayload,
		search,
		selectedKeys: selectedKey,
		order,
		orderBy:"createdAt"
		
	}
	if(country!==0&&country!=="0")
	{
		params.countryId=country
	}
	if(status!==WORK.STATUS.ALL)
	{
		params.status=status
	}

	return axios.get(ApiRoutes.WORK.getAll, {params:params});
};

export const getWorkDashboard=()=>{
	return axios.get(ApiRoutes.WORK.dashboard);
}

export const getSpecifiedWork=(id)=>{
	return axios.get(`${ApiRoutes.WORK.getAll}/${id}`)
}
export const changeStatus=(status, id)=>{
	return axios.put(`${ApiRoutes.WORK.getAll}/${id}`, {workStatus:status})

}