import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";
import { TIMESHEETS } from "../../../utils/timesheets";

export const getAll = ( paginationPayload, selectedKeys, search, order) => {
	
	const params={
		...paginationPayload,
		selectedKeys,
		search,
		orderBy:"createdAt",
		order
		
		
	}
	
	
	return axios.get(ApiRoutes.INVOICE.getAll, {params:params});
};


export const getSpecificTimesheet=(id)=>{
	return axios.get(`${ApiRoutes.TIMESHEETS.getAll}/${id}`)
}
export const  changeStatus=(status, id)=>{
	return axios.put(`${ApiRoutes.TIMESHEETS.getAll}/${id}`, {status:status})
}
export const allTimesheet=(status, userId, workId, businessId, paginationPayload)=>{
	const statusPayload={}
	if(status!==TIMESHEETS.STATUS.ALL)
	{
		statusPayload.status=status
		
	}
	return axios.get(`${ApiRoutes.INVOICE.SPECIFIC_INVOICE}`, {params:{userId, workId, businessId, ...paginationPayload, ...statusPayload}})
}
