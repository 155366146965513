import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../../common/Layout";
import styles from "./style.module.css";
import upl from "./../../assets/images/upl.png"
import upload from "../../assets/images/upload.png"
import hnj from "../../assets/images/hnj.png"

function Editform() {
	return (
		<Layout>
			<h2>Editable Form</h2>
			<div className={styles.tableTime}>
				<div className={styles.topTime}>
					<h5>
                        Timesheet <i className="fas fa-info-circle"></i>
					</h5>
					<div className={styles.topTimeInner}>
						<div className={styles.filters}>
							<div className={styles.Formgroup}>
								<label>Arrange : </label>
								<select>
									<option>Columns</option>
									<option>Columns</option>
								</select>
							</div>
							<div className={styles.Formgroup}>
								<label>Select : </label>
								<select>
									<option>Columns</option>
									<option>Columns</option>
								</select>
							</div>
						</div>
						<button className={styles.exportButton}>
							<img src={upload}  alt="" /> Export
						</button>
					</div>
				</div>
				<div className={styles.Tabstable}>
					<ul>
						<li className={styles.active}>
							<Link>
                                Invoice from Professional to AA
							</Link>
						</li>
    
						<li className="">
							<Link>
                                SSA Form
							</Link>
						</li>
                           
					</ul>
                    
				</div>
				<div className={styles.formPage}>
					<form>
						<h4>MSA Form</h4>
						<div className="row">
							<div className="col-md-12">
								<div className={styles.formGroup}>
									<label>Add Header Image</label>
									<div className={styles.ImageUload}>
										<input type="file" placeholder="smtp.sendgrid.net"/>
										<div className={styles.ImageLay}>
											<img src={upl} alt=""/>
											<span>Choose File  <img src={hnj} alt=""/></span>
											<p>No File Choosen</p>
										</div>

									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Host</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Username</label>
									<input type="text" placeholder="Eg. California"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Host</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Username</label>
									<input type="text" placeholder="Eg. California"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Host</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Username</label>
									<input type="text" placeholder="Eg. California"/>
								</div>
							</div>
                                
						</div>
                            
                               
						<div className="row">
                               
                             
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<button >Cancel</button>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<input type="submit" value="Submit"/>
								</div>
							</div>
						</div>
					</form>
                        
				</div>
			</div>
		</Layout>
	)
}
    
export default Editform