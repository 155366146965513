import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";
// import { MASTER } from "../../../utils/master";

export const getMasterByType = (type, paginationPayload, search, selectedKeys, orderBy, order) => {
	const params={
		...paginationPayload,
		type,
		search, selectedKeys, orderBy:"createdAt", order,
		
	}
	if(orderBy!=="All")
	{
		params.status=orderBy
	}
	return axios.get(ApiRoutes.MASTER.getAll, {params:params});
};


export const addType = (payload) => {
	return axios.post(ApiRoutes.MASTER.getAll, payload);
};
export const changeStatus=(status, id)=>{
	return axios.patch(`${ApiRoutes.MASTER.getAll}/${id}`, {status})

}
export const getSpecifiedMaster=(id)=>{
	return axios.get(`${ApiRoutes.MASTER.getAll}/${id}`)

}
export const removeMaster=(id)=>{
	return axios.delete(`${ApiRoutes.MASTER.getAll}/${id}`)
}
export const updateSpecifiedMaster=(id, name)=>{
	return axios.put(`${ApiRoutes.MASTER.getAll}/${id}`, name)
	
}
export const getDashboardInfo = () => {
	return axios.get(ApiRoutes.MASTER.dashboard);
};
