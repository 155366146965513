import * as Yup from "yup";

import { VALIDATIONS } from "../../../../../utils/yup_validations";

const AddPlanSchema = Yup.object()
	.shape({
		type: Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
		price: Yup.number().min(1).positive().required(),
		durationType:Yup.string().required(),
		duration:Yup.string().required()
		
	})


export { AddPlanSchema };
