import Axios from "axios";

import { API_URL } from "../config";
import storage from "../utils/storage";
import { RoutesUrl } from "../utils/RoutesUrl";

function authRequestInterceptor(config) {
	const token = storage.getToken();

	config.headers = config.headers || {};

	if (token) {
		config.headers.token = token
	}
	config.headers.Accept = "application/json";
	return config;
}

export const axios = Axios.create({
	baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		if(error.response.status===401||error.response.status===403)
		{
			storage.clearToken();
	   window.location = RoutesUrl.LOGIN;
		   return Promise.resolve();
		   
		}
		 return Promise.reject(error.response);
	}
);