import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleXmark,
	faCircleCheck,
	faClock,
	faStar,
} from "@fortawesome/free-solid-svg-icons";

import detailstyle from "../detail.module.css";
import styles from "../style.module.css";
// import Topbar from "../../../common/Topbar";
// import Sidebar from "../../../common/Sidebar";
import { getSpecifiedReview } from "../api";
//import tabpro from "../../../assets/images/tabpro.png";
import { POSTS } from "../../../utils/posts";
import {Layout} from "../../../common/Layout";


const ViewMoreReview = () => {
	const { id } = useParams();
	const [reviewData, setreviewData] = useState();
	const [loading, setLoading] = useState(true);

	const stars=(count)=>{
		const icons=[];
		for(var i=0; i<count; i++)
		{
			icons.push(  <FontAwesomeIcon
				size="xs"
				icon={faStar}
			/>
			)
		}
		return icons
	}

	useEffect(() => {
		getSpecifiedReview(id).then((res) => {
			const { data } = res;
			setreviewData(data);
			setLoading(false);
			console.log(data);
		});
	}, []);

	return (
		<Layout>
			<div className={detailstyle.uttrePad}>
				<div className={detailstyle.heaingFilter}>
					<h3>Review Details</h3>
				</div>
				{!loading ? (
					<div className={detailstyle.tableData}>
						<div className={detailstyle.addBtn}></div>
						<div className="row">
							<div className="col-md-3">
								<h5>Review For:</h5>
								<div className={detailstyle.leftProfile}>
									<div className={detailstyle.proImage}>
										<img src={reviewData?.user.profilePicture} width={50} height={50} alt="" />
									</div>
									<h4>{reviewData?.user.name}</h4>
									<div className={detailstyle.stars}>
										{/* <label>4.5</label> */}
									</div>
											
								</div>
                                       
							</div>
                                   
							<div className="col-md-9">
								<div className={detailstyle.rightProfile}>
									<h5>Review By:</h5>

									<div className={detailstyle.leftProfile}>
										<div className={detailstyle.proImage}>
											<img src={reviewData?.user.profilePicture} width={50} height={50} alt="" />
										</div>
										<p>{reviewData?.user.name}</p>
										<div className={detailstyle.stars}>
											{/* <label>4.5</label> */}
										</div>
    
									</div>
									<h4>Status</h4>
									<p>
										<span
											className={
												reviewData?.status === POSTS.STATUS.REJECTED
													? styles.rejected
													: reviewData?.status === POSTS.STATUS.APPROVED ?
														styles.approved: styles.pending
											}
										>
											{"  "}
											<FontAwesomeIcon
												icon={
													reviewData?.status === POSTS.STATUS.REJECTED
														? faCircleXmark
														: reviewData?.status === POSTS.STATUS.APPROVED
															? faCircleCheck
															: faClock
												}
											/>
											{" "}
											{reviewData?.status[0].toUpperCase() +
                reviewData?.status.slice(1)}
										</span>
									</p>
									<h4>Created At</h4>
									<p>
										{moment(reviewData?.createdAt).format("MMM DD, YYYY")}
									</p>
									<h4>Rating</h4>
									<p>{stars(Number(reviewData.rating)).map((val)=>val)}</p>
											
									<br />
								</div>
							</div>
							<h4>Review</h4>
							<p>{reviewData?.review}</p>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default ViewMoreReview;
