import React from "react"
import { useSelector } from "react-redux";

import styles from "./styles/style.module.css"
import Topbar from "../Topbar";
import Sidebar from "../Sidebar";

const Layout = ({children}) => {
	const sideBarStatus = useSelector((state) => state.sideBar.show);

	return (
		<div className={styles.pageOuter}>
			<Topbar />
			<div className={`${styles.pageContainer} ${sideBarStatus?styles.active:""} `}>
				<div className={styles.leftContainer}>
					<Sidebar />
				</div>
				<div className={styles.rightContainer}>{children}
				</div>
			</div>
		</div>
	)
}

export {Layout}