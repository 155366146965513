/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {  Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./style.module.css";
// import cmlogo from "../../assets/images/cmlogo.png";
// import payone from "../../assets/images/payone.png"
// import paytwo from "../../assets/images/paytwo.png"
// import paythree from "../../assets/images/paythree.png"
// import grarow from "../../assets/images/grarow.png";
// import blarow from "../../assets/images/blarow.png";
import commonStyles from "../../common/common.module.css";
// import AddType from "./modals/AddType";
// import RemoveMaster from "./modals/RemoveMaster";
// import EditMaster from "./modals/EditMaster";
// import { editMasters } from "../../redux/slices/masterSlice";
import {useInfiniteScroll} from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
// import SelectColumns from "./modals/SelectColumns";
// import { USER } from "../../utils/user";
import { getAllDocumens } from "./api";

function EditableDocument() {
  const [occupation, setOccupation] = useState([]);
  const [hasMore, setHasMore]=useState(true);
const [page, setPage]=useState(1);
  const [loading, setLoading]=useState(true);

const navigate=useNavigate()
	const elementRef = useRef(null);

  
	const fetchMore=()=>{
    setLoading(true)
		getAllDocumens( {page, limit:10}).then((res)=>{
    //   setLoading(false)
			const {data}=res;
			if(data.length===0)
			{
				setHasMore(false)
			}
			else{
        if(page===1){
          setOccupation(data);
        }
        else{
          setOccupation(prev=>[...prev, ...data])
          
        }
        setPage(prev=>prev+1)
			}
			
		})

	}

	useInfiniteScroll(occupation, elementRef, hasMore, fetchMore);

	
  


  return (
   <Layout>
    
          <h2>Editable Documents</h2>
          <div className={styles.OuterTime}>
          
          </div>
          <div className={styles.tableTime}>
            <div className={styles.topTime}>
              <h5>
                Documents <i className="fas fa-info-circle"></i>
              </h5>
              {/* <div className={styles.topTimeInner}>
                <form className={styles.formGroup} 
                onSubmit={(e)=>{
                e.preventDefault();
                handleSearch()}
              }>
                <input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
                <button onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
                <button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>
              </form>
              <button onClick={handleExcelDownload} className={styles.exportButton}>
                  <img src={upload} alt="" /> Export
                </button>
              </div> */}
              
              {/* <div className={styles.filters}>
                <div className={styles.Formgroup}>
                  <label>Arrange : </label>
                  <select>
                    <option>Columns</option>
                    <option>Columns</option>
                  </select>
                </div>
                <div className={styles.Formgroup}>
                  <label>Select : </label>
                  <select>
                    <option>Columns</option>
                    <option>Columns</option>
                  </select>
                </div>
              </div> */}
              {/* <button>
                <img src={upload} alt="" /> Import
              </button> */}
              {/* <button>
                <img src={upload} alt="" /> Excel Format
              </button> */}
              {/* <button
                onClick={() => setShowAddTypeModal(true)}
                disabled={loading}
              >
                <img src={upload} alt="" /> Add{" "}
                {masterType === "softwarelicences"
                  ? "Software Licences"
                  : masterType.charAt(0).toUpperCase() + masterType.slice(1)}
              </button> */}
            </div>
            {/* <div className={`${styles.extrafilter} ${styles.topTime}`}>
					<div className="">
				
						<button onClick={()=>setShowSelectModal(true)} className={styles.selectButton}>
							<FontAwesomeIcon
								// className={commonStyles.iconmargin}
								icon={faTableColumns}
							/>
							<span>Select Columns</span>
						</button>
					</div>
					<div className={styles.FilterBtn}>
						<Form.Group className="from-group" controlId="type">
							<Form.Label>Status:</Form.Label>
							<Form.Control
										
								value={orderBy}
								as="select"
								onChange={(e)=>handleActiveOrder(e.target.value)}
	
										
							>
								{
									["All","approved","pending","rejected"].map((item)=><option value={item} key={1}>
										{item}
									</option>)
								}
								
									
							</Form.Control>
						</Form.Group>

						<Form.Group className="from-group" controlId="type">
							<Form.Label>Order By Date:</Form.Label>
							<Form.Control
										
								value={order}
								as="select"
								onChange={(e)=>handleOrder(e.target.value)}
	
										
							>
								
								<option value={1} key={1}>
						Ascending
								</option>
								<option value={-1} key={2}>
						Descending
								</option>
								
									
							</Form.Control>
						</Form.Group>
					
					</div>
				</div> */}
            {/* <div className={styles.Tabstable}>
              <ul>
                <li
                  className={
                    activeTab === MASTER.TYPE.OCCUPATIONS && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.OCCUPATIONS)}
                  >
                    Occupations
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.INDUSTRIES && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.INDUSTRIES)}
                  >
                    Industries
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.MANUFACTURERS && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.MANUFACTURERS)}
                  >
                    Manufacturers
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.SOFTWARE_LICENCES && styles.active
                  }
                >
                  <Link
                    onClick={() =>
                      handleActivePanel(MASTER.TYPE.SOFTWARE_LICENCES)
                    }
                  >
                    Software Licences
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.APPLICATIONS && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.APPLICATIONS)}
                  >
                    Applications
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.REFERRALS && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.REFERRALS)}
                  >
                    Referrals
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.COMPANIES && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.COMPANIES)}
                  >
                    Companies
                  </Link>
                </li>
                <li
                  className={activeTab === MASTER.TYPE.DEGREE && styles.active}
                >
                  <Link onClick={() => handleActivePanel(MASTER.TYPE.DEGREE)}>
                    Degree
                  </Link>
                </li>
                <li
                  className={activeTab === MASTER.TYPE.SCHOOL && styles.active}
                >
                  <Link onClick={() => handleActivePanel(MASTER.TYPE.SCHOOL)}>
                    School
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.SPECIALIZATIONS && styles.active
                  }
                >
                  <Link
                    onClick={() =>
                      handleActivePanel(MASTER.TYPE.SPECIALIZATIONS)
                    }
                  >
                    Specializations
                  </Link>
                </li>
              </ul>
            </div> */}
              <div className={styles.Tbalelayout}>
                <table>
                  <tr>
               <th>Name</th>
                   
                    <th>Action</th>
                  </tr>
                {occupation.map((doc)=>{
                    return <tr>
                        <td>{doc.name}</td>
                        <td>
									<Dropdown className={commonStyles.tddropdown}>
										<Dropdown.Toggle className={styles.dropdownIcon}>
											{/* <a className="me-2"></a> */}
                                                               
											<FontAwesomeIcon
												// className={commonStyles.iconmargin}
												icon={faEllipsisH}
											/>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => {
													// dispatch(editWork({ id: user._id }));
													// setShowEditModal(true);
													 navigate(`${"/cms"}/${doc._id}`)
												}}
											>
												<FontAwesomeIcon
													size="xs"
													className={commonStyles.iconmargin}
													icon={faEye}
												/>
																View And Edit
											</Dropdown.Item>
											
																
										</Dropdown.Menu>
									</Dropdown>
								</td>
                    </tr>
                    
                })}
                  
                </table>
                {hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div></div>}
              </div>
          </div>
      
  
   </Layout>
  );
}

export default EditableDocument;
