import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link, useParams } from "react-router-dom";

import info from "../../../assets/images/info_icon.svg";
import pen from "../../../assets/images/pen.png";
import profile_picture from "../../../assets/images/profile_picture.png";
import socone from "../../../assets/images/socone.png";
import soctwo from "../../../assets/images/soctwo.png";
import socfour from "../../../assets/images/socfour.png";
import socthree from "../../../assets/images/socthree.png";
import socfive from "../../../assets/images/socfive.png";
import share from "../../../assets/images/share.png";
import pls from "../../../assets/images/pls.png";
import pluscircle from "../../../assets/images/plus_circle.svg";
import chatdot from "../../../assets/images/chatdot.png";
import stras from "../../../assets/images/stras.png";
import pro from "../../../assets/images/pro.png";
// import send from "../../..//assets/images/send.png";
import ltarow from "../../../assets/images/ltarow.png";
import loc from "../../../assets/images/loc.png";
import rate from "../../../assets/images/rate.png";
import skshare from "../../../assets/images/skshare.png";
import exper from "../../../assets/images/exper.png";
import styles from "../routes/styles.module.css";
import {  getSpecificUserDetail } from "../api";
import { Layout } from "../../../common/Layout";

const ViewProfile = () => {
	const {id}=useParams()
	const [profile, setProfile]=useState()
	const[loading, setLoading]=useState(true);

	useEffect(() => {
		getSpecificUserDetail(id).then((res)=>{
			setProfile(res.data);
			setLoading(false)
		})
	}, [])
    
	var settings = {
		dots: false,
		speed: 500,
		slidesToShow: 3.2,
		slidesToScroll: 1,
		initialSlide: 0,
		infinite: false,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 3.2,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2.2,
					slidesToScroll: 1,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1.2,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<>
			<Layout>
			
				{ !loading?<div className={styles.viewProiflePage}>
					<div className="container">
							
						<div className={styles.viewProfileLeft}>
							<h3>
              Profile <img src={info} alt="" />{" "}
							</h3>
							<div className={styles.profileHead}>
								{/* <div className={styles.profileCover}>
                <img src={profileCover} alt="" />
                <span>
                  {" "}
                  <img src={pen} alt="" />
                </span>
              </div> */}
								<div className={styles.profileName}>
									<div className={styles.prfoleHead}>
										<img src={profile?.profilePicture?profile.profilePicture:""} alt="" />
										<div className="form-check form-switch">

											<div className={styles.leftContract}>
												<button className={styles.CoptText}>
													<i className="far fa-copy"></i> Copy
												</button>
												<img src={share} alt="" />
											</div>
										</div>
									</div>
									<h4>{profile?.name?profile?.name:""} <span>
										<img src={rate} alt="" />
										{profile?.avgRating}
									</span>
									<span></span></h4>
									<p>
										{profile?.occupationDetails?.map((occupation)=>{
											return `${occupation?.name} ||`
										})}
                 
									</p>
									<div className={styles.location}>

										<span>
											<img src={loc} alt="" />
											{profile?.address}
										</span>
									</div>
									{/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sed luctus, augue eget scelerisque efficitur.
                </p> */}

									<div className={styles.PayRate}>
										<h5>Pay Rates</h5>
										<ul>
											<li>
                   
												<label>Base</label>
												<span>$ {profile?.rates?.baseRate}</span>
											</li>
											<li>
												<label>Overtime</label>
												<span>$ {profile?.rates?.overTimeRate}</span>
											</li>
											<li>
												<label>Sun/Holidays</label>
												<span>${profile?.rates?.doubleTimeRate}</span>
											</li>
										</ul>
									</div>
									<div className={styles.connectBtns}>
										<button>+ Connect</button>
										<span>Available</span>
										<img src={chatdot} alt="" />
									</div>
									{/* <div className={styles.BostProfile}>
                  <button>Boost Profile</button>
                  <ul>
                    <li>
                      <span>Share :</span>
                    </li>
                    <li>
                      <a href="#">
                        <img src={socone} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={soctwo} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={socthree} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={socfour} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={socfive} alt="" />
                      </a>
                    </li>
                  </ul>
                </div> */}
								</div>
							</div>

							<div className={styles.skillset}>

								<h4>About</h4>
								<p>
									{profile?.bio}
								</p>

							</div>


							<div className={styles.reviewSection}>
								<h3>
                Reviews <img src={info} alt="" />
								</h3>
								{/* <div className={`${styles.reviwSection} ${styles.noborder}`}>
                <div className={styles.revieStar}>
                  <span>
                    <img src={pro} alt="" />
                  </span>
                  <div className="">
                    <h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
                    <img src={stras} alt="" />
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sed dapibus eros eu vehicula interdum. Cras nec ultricies
                  massa. Curabitur rutrum,{" "}
                </p>
              </div> */}

								{/* <div className={styles.textReview}>
                <span>
                  <img src={pro} alt="" />
                </span>
                <div className={styles.formGroup}>
                  <input type="text" placeholder="Write a reply..." />
                  <label>
                    <img src={send} alt="" />
                  </label>
                </div>
              </div> */}
								<div className={styles.reviwSection}>
									<div className={styles.revieStar}>
										<span>
											<img src={pro} alt="" />
										</span>
										<div className="">
											<h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
											<img src={stras} alt="" />
										</div>
										<div className={styles.reply}>
											<a href="#">
												<img src={ltarow} alt="" />
                      Reply
											</a>
										</div>
									</div>
									<p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sed dapibus eros eu vehicula interdum. Cras nec ultricies
                  massa. Curabitur rutrum,{" "}
									</p>
								</div>
								<div className={styles.reviwSection}>
									<div className={styles.revieStar}>
										<span>
											<img src={pro} alt="" />
										</span>
										<div className="">
											<h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
											<img src={stras} alt="" />
										</div>
										<div className={styles.reply}>
											<a href="#">
												<img src={ltarow} alt="" />
                      Reply
											</a>
										</div>
									</div>
									<p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sed dapibus eros eu vehicula interdum. Cras nec ultricies
                  massa. Curabitur rutrum,{" "}
									</p>
								</div>
								<div className={styles.reviwSection}>
									<div className={styles.revieStar}>
										<span>
											<img src={pro} alt="" />
										</span>
										<div className="">
											<h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
											<img src={stras} alt="" />
										</div>
										<div className={styles.reply}>
											<a href="#">
												<img src={ltarow} alt="" />
                      Reply
											</a>
										</div>
									</div>
									<p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sed dapibus eros eu vehicula interdum. Cras nec ultricies
                  massa. Curabitur rutrum,{" "}
									</p>
								</div>
								<div className={styles.reviwSection}>
									<div className={styles.revieStar}>
										<span>
											<img src={pro} alt="" />
										</span>
										<div className="">
											<h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
											<img src={stras} alt="" />
										</div>
										<div className={styles.reply}>
											<a href="#">
												<img src={ltarow} alt="" />
                      Reply
											</a>
										</div>
									</div>
									<p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sed dapibus eros eu vehicula interdum. Cras nec ultricies
                  massa. Curabitur rutrum,{" "}
									</p>
								</div>

								<div className={styles.loadMore}>
									<a href="#">View More</a>
								</div>
							</div>


							<div className={styles.skillset}>

								<h3>Endorsements <img src={pluscircle} alt="" /></h3>
								<div className={styles.ebdrotabs}>
									<ul>
										<li className={styles.active}><span>Received</span></li>
										<li><span>Given</span></li>
									</ul>
								</div>
								<div className={styles.endroProfile}>
									<span> <img src={profile_picture} alt="" /></span>
									<div>
										<h5>Nick Kroll</h5>
										<h6>UI/UX Designer</h6>
										<span>2 months ago</span>
									</div>
								</div>
								<p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dapibus eros eu vehicula interdum. Cras nec ultricies massa. Curabitur rutrum, diam id consequat consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dapibus eros eu vehicula interdum. Cras nec ultricies massa. Curabitur rutrum, diam id consequat consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dapibus eros eu vehicula interdum. Cras nec ultricies massa. Curabitur rutrum, diam id consequat consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dapibus eros eu vehicula interdum. Cras nec ultricies massa. Curabitur rutrum, diam id consequat consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dapibus eros eu vehicula interdum. Cras nec ultricies massa. Curabitur rutrum, diam id consequat consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dapibus eros eu vehicula interdum. Cras nec ultricies massa. Curabitur rutrum, diam id consequat consequat.
								</p>

							</div>
							<div className={styles.skillset}>
								<div className={styles.skilhead}>
									<h3>
                  Technical Skills <img src={info} alt="" />
									</h3>

								</div>
								<ul>
									{
										profile?.skillsDetails.map((skill, index)=> <li key={index}>
											<span >{skill.name}</span>
										</li>)
									}
               
								</ul>
							</div>
						</div>

						<div className={styles.skillset}>
							<div className={styles.skilhead}>
								<h3>
                Professional Experience <img src={info} alt="" />
								</h3>
								<div className={styles.skilladd}>
									<span>
										{" "}
										<img src={pen} alt="" />
									</span>
									<span>
										{" "}
										<img src={pls} alt="" />
									</span>
									<span>
										{" "}
										<img src={skshare} alt="" />
									</span>
								</div>
							</div>
							<div className={styles.experset}>
								<span>
									{" "}
									<img src={exper} alt="" />
								</span>
								<div>
									<h4>Self Employed-Freelancer</h4>
									<h5>4 Years 2 months (Mar 2020 - Present)</h5>
									<p>
                  In this role dedicated to the control and automation of
                  automotive and industrial processes. My responsibilities
                  include integrating new process stations or lines, supporting
                  start-ups, designing, and debugging PLC programs in Allen
                  Bradley, and ensuring the correct placement of auto-parts
                  validated by cameras, sensors, and scanning. I have also
                  worked on safety task logic and collaborated closely with
                  customers in Mexico and the US, including notable names like
                  Plastic Omnium, Volkswagen, Mercedes Benz, Nissan, and
                  Symbotic at a Walmart DC Warehouse, among others.
									</p>
								</div>
							</div>
							<div className={styles.experset}>
								<span>
									{" "}
									<img src={exper} alt="" />
								</span>
								<div>
									<h4>Self Employed-Freelancer</h4>
									<h5>4 Years 2 months (Mar 2020 - Present)</h5>
									<p>
                  In this role dedicated to the control and automation of
                  automotive and industrial processes. My responsibilities
                  include integrating new process stations or lines, supporting
                  start-ups, designing, and debugging PLC programs in Allen
                  Bradley, and ensuring the correct placement of auto-parts
                  validated by cameras, sensors, and scanning. I have also
                  worked on safety task logic and collaborated closely with
                  customers in Mexico and the US, including notable names like
                  Plastic Omnium, Volkswagen, Mercedes Benz, Nissan, and
                  Symbotic at a Walmart DC Warehouse, among others.
									</p>
								</div>
							</div>
						</div>

						<div className={styles.skillset}>
							<div className={styles.skilhead}>
								<h3>
                Company Detail <img src={info} alt="" />
								</h3>
								<div className={styles.skilladd}>
									<span>
										{" "}
										<img src={pen} alt="" />
									</span>
									<span>
										{" "}
										<img src={pls} alt="" />
									</span>
									<span>
										{" "}
										<img src={skshare} alt="" />
									</span>
								</div>
							</div>
							<div className={styles.experset}>
								<span>
									{" "}
									<img src={exper} alt="" />
								</span>
								<div className={styles.compDetails}>
									<h4>Self Employed-Freelancer</h4>
									<h5>4 Years 2 months (Mar 2020 - Present)</h5>
									<p>
                  In this role dedicated to the control and automation of
                  automotive and industrial processes. My responsibilities
                  include integrating new process stations or lines, supporting
                  start-ups, designing, and debugging PLC programs in Allen
                  Bradley, and ensuring the correct placement of auto-parts
                  validated by cameras, sensors, and scanning. I have also
                  worked on safety task logic and collaborated closely with
                  customers in Mexico and the US, including notable names like
                  Plastic Omnium, Volkswagen, Mercedes Benz, Nissan, and
                  Symbotic at a Walmart DC Warehouse, among others.
									</p>
									<div className={styles.compDeteails}>
										<div>
											<p>Address : </p>
											<p>
                      456 Park Avenue <br />
                      New York NY 10022 <br /> www.companyname.com{" "}
											</p>
										</div>

										<div className={styles.BostProfile}>
											<ul>
												<li>
													<span>Share :</span>
												</li>
												<li>
													<a href="#">
														<img src={socone} alt="" />
													</a>
												</li>
												<li>
													<a href="#">
														<img src={soctwo} alt="" />
													</a>
												</li>
												<li>
													<a href="#">
														<img src={socthree} alt="" />
													</a>
												</li>
												<li>
													<a href="#">
														<img src={socfour} alt="" />
													</a>
												</li>
												<li>
													<a href="#">
														<img src={socfive} alt="" />
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className={styles.productOffers}>
								<div className={styles.skilhead}>
									<h4>Company Detail </h4>
									<div className={styles.skilladd}>
										<span>
											{" "}
											<img src={pen} alt="" />
										</span>
										<span>
											{" "}
											<img src={pls} alt="" />
										</span>
										<span>
											{" "}
											<img src={skshare} alt="" />
										</span>
									</div>
								</div>

								<div className="proSlider">
									<Slider {...settings}>
										<div className={styles.proslideOut}>
											<div className={styles.proslideOutHead}>
												<div className={styles.leftProHead}>
													<span>#2999</span>
													<h4>Product Name</h4>
												</div>
												<div className={styles.leftProHeadShare}>
													<img src={skshare} alt="" />
												</div>
											</div>
											<p>
                      Our online coaching platform for students provides
                      personalized learning experiences and expert guidance to
                      help them achieve academic success.
											</p>
											<Link to="#">View Details</Link>
										</div>
										<div className={styles.proslideOut}>
											<div className={styles.proslideOutHead}>
												<div className={styles.leftProHead}>
													<span>#2999</span>
													<h4>Product Name</h4>
												</div>
												<div className={styles.leftProHeadShare}>
													<img src={skshare} alt="" />
												</div>
											</div>
											<p>
                      Our online coaching platform for students provides
                      personalized learning experiences and expert guidance to
                      help them achieve academic success.
											</p>
											<Link to="#">View Details</Link>
										</div>
										<div className={styles.proslideOut}>
											<div className={styles.proslideOutHead}>
												<div className={styles.leftProHead}>
													<span>#2999</span>
													<h4>Product Name</h4>
												</div>
												<div className={styles.leftProHeadShare}>
													<img src={skshare} alt="" />
												</div>
											</div>
											<p>
                      Our online coaching platform for students provides
                      personalized learning experiences and expert guidance to
                      help them achieve academic success.
											</p>
											<Link to="#">View Details</Link>
										</div>
										<div className={styles.proslideOut}>
											<div className={styles.proslideOutHead}>
												<div className={styles.leftProHead}>
													<span>#2999</span>
													<h4>Product Name</h4>
												</div>
												<div className={styles.leftProHeadShare}>
													<img src={skshare} alt="" />
												</div>
											</div>
											<p>
                      Our online coaching platform for students provides
                      personalized learning experiences and expert guidance to
                      help them achieve academic success.
											</p>
											<Link to="#">View Details</Link>
										</div>
									</Slider>
								</div>

								<div className="accountPay">
									<ul>
										<li>
											<h4>Company Accounts Payable Contact</h4>
											<p>Name : Johns Yanni rai</p>
											<p>Email : example@gmail.com</p>
											<p>Phone : +91 09876543</p>
										</li>
										<li>
											<h4>Company Accounts Payable Contact</h4>
											<p>Name : Johns Yanni rai</p>
											<p>Email : example@gmail.com</p>
											<p>Phone : +91 09876543</p>
										</li>
										<li>
											<h4>Company Accounts Payable Contact</h4>
											<p>Name : Johns Yanni rai</p>
											<p>Email : example@gmail.com</p>
											<p>Phone : +91 09876543</p>
										</li>
										<li>
											<h4>Company Accounts Payable Contact</h4>
											<p>Name : Johns Yanni rai</p>
											<p>Email : example@gmail.com</p>
											<p>Phone : +91 09876543</p>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className={styles.skillset}>
							<div className={styles.skilhead}>
								<h3>
                Skilled Trades <img src={info} alt="" />
								</h3>
								<div className={styles.skilladd}>
									<span>
										{" "}
										<img src={pen} alt="" />
									</span>
									<span>
										{" "}
										<img src={pls} alt="" />
									</span>
									<span>
										{" "}
										<img src={skshare} alt="" />
									</span>
								</div>
							</div>
							<div className={styles.RateSatr}>
								<h5>
                Electrician <img src={stras} alt="" />
								</h5>
								<p>
                Hands-on skills in electrical wiring, system diagnostics, and
                safety standards
								</p>
							</div>
						</div>

						<div className={styles.skillset}>
							<div className={styles.skilhead}>
								<h3>
                Interview Questions
									<img src={info} alt="" />
								</h3>
								<div className={styles.skilladd}>
									<span>
										{" "}
										<img src={pen} alt="" />
									</span>
									<span>
										{" "}
										<img src={pls} alt="" />
									</span>
									<span>
										{" "}
										<img src={skshare} alt="" />
									</span>
								</div>
							</div>
							<div className={styles.InterviewQues}>
								<ul>
									<li>
                  1. Willing to work more than 60 miles from home ?{" "}
										<span>Ans : Yes</span>
									</li>
									<li>
                  1. Willing to work more than 60 miles from home ?{" "}
										<span>Ans : Yes</span>
									</li>
									<li>
                  1. Willing to work more than 60 miles from home ?{" "}
										<span>Ans : Yes</span>
									</li>
									<li>
                  1. Willing to work more than 60 miles from home ?{" "}
										<span>Ans : Yes</span>
									</li>
									<li>
                  1. Willing to work more than 60 miles from home ?{" "}
										<span>Ans : Yes</span>
									</li>
									<li>
                  1. Willing to work more than 60 miles from home ?{" "}
										<span>Ans : Yes</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>:(
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				)}
			</Layout>
			
		
		</>
	);
};

export default ViewProfile;
