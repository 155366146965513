import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice"
import masterReducer from "./slices/masterSlice"
import postReducer from "./slices/postSlice"
import galleryReducer from "./slices/gallerySlice"
import sideBarReducer from "./slices/sideBarSlice"
import inquiryReducer from "./slices/inquirySlice"
import reviewReducer from "./slices/reviewSlice"
import workReducer from "./slices/workSlice";
import templatesReducer from "./slices/templates";

const store = configureStore({ reducer: {
	users:userReducer,
	masters:masterReducer,
	posts:postReducer,
	gallery:galleryReducer,
	inquiry:inquiryReducer,
	sideBar:sideBarReducer,
	review:reviewReducer,
	work: workReducer,
	template:templatesReducer,
} });

export default store;
