import React, { useEffect, useState } from "react"

import styles from "./style.module.css";
// import logo from "../../assets/images/logo.png";
// import search from "../../assets/images/search.png";
// import env from "../../assets/images/env.png";
// import bell from "../../assets/images/bell.png";
// import avatar from "../../assets/images/avatar.png";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
// import graph from "../../assets/images/graph.png"
// import right from "../../assets/images/right.png"
// import payone from "../../assets/images/payone.png"
// import paytwo from "../../assets/images/paytwo.png"
// import paythree from "../../assets/images/paythree.png"
import info from "../../assets/images/info.png"
import grarow from "../../assets/images/grarow.png"
import barrow from "../../assets/images/rarrow1.png"
import blarow from "../../assets/images/blarow.png"
import { getDashboard, getDashboardGraph } from "./api";
import Graph from "../../Components/Graph"
import { DASHBOARD } from "../../utils/dashboard";

function Dashboard() {

	const [dashboardData, setDashboardData] = useState();
	const [loading, setLoading]=useState(true)
	const [graphData, setGraphData]=useState({
		revenueGraphData:null,
		workReportGraphData:null,
		timesheetGraphData:null,
		postGraphData:null,
		workGraphData:null,

	})


	useEffect(() => {
		Promise.all([getDashboard(), getDashboardGraph(DASHBOARD.GRAPH_TYPES.REVENUE), getDashboardGraph(DASHBOARD.GRAPH_TYPES.WORK_REPORT), getDashboardGraph(DASHBOARD.GRAPH_TYPES.TIMESHEET), getDashboardGraph("posts"), getDashboardGraph("works")]).then((res)=>{
			console.log(res[5].data, "work Data")
			setDashboardData(res[0].data);
			setGraphData({revenueGraphData:res[1].data, workReportGraphData:res[2].data, timesheetGraphData:res[3].data, postGraphData:res[4].data, workGraphData:res[5].data})
			setLoading(false)
		})

	}, [])
	
	return (
		<div className={styles.pageOuter}>
			<Topbar/>
			<div className={styles.pageContainer}>
				<div className={styles.leftContainer}>
					<Sidebar/>
				</div>
				<div className={styles.rightContainer}>
					<h2>My Dashboard</h2>
					{/* <div className={styles.OuterTime}>
						<div className='row'>
							<div className='col-md-4'>
								<div className={`${styles.firstTimesheet} ${styles.timeShetet}`}>
									<span><img src={payone} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Timesheets</h6>
										<h5>{dashboardData?.timesheets} <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className={`${styles.secTimesheet} ${styles.timeShetet}`}>
									<span><img src={paytwo} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Expenses</h6>
										<h5>{dashboardData?.expenses} <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className={`${styles.thirdTimesheet} ${styles.timeShetet}`}>
									<span><img src={paythree} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Invoices</h6>
										<h5>{dashboardData?.invoices} <label>More <img src={right} alt="" /></label></h5>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					{!loading?<>
						<div className={styles.OuterTime}>
							<div className='row'>
								<div className='col-md-3'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Total Revenue <img src={info} alt="" /> </h6>
											{dashboardData?.revenue.revenuePercentageChange<0?<span  className={styles.loss}><img src={barrow} alt="" /> {dashboardData?.revenue.revenuePercentageChange}%</span>:<span className={styles.profit}><img src={grarow} alt="" /> {dashboardData?.revenue.revenuePercentageChange}%</span>
											}
										</div>
										<h5>${dashboardData?.revenue.totalRevenue}</h5>
										<div className={styles.revenueBoxFoter}>
											<h4>+${dashboardData?.revenue.lastMonthRevenue} <span>from last month</span></h4>
											{/* <span>More <img src={blarow} alt="" /></span> */}
										</div>
                  
									</div>
								</div>
								<div className='col-md-3'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Total Users <img src={info} alt="" /> </h6>
											{dashboardData?.users.userPercentageChange<0?<span className={styles.loss}><img src={barrow} alt="" /> {dashboardData?.users.userPercentageChange}%</span>:<span className={styles.profit}><img src={grarow} alt="" /> {dashboardData?.users.userPercentageChange}%</span>}
										</div>
										<h5>{dashboardData?.users.totalUsers}</h5>
										<div className={styles.revenueBoxFoter}>
											<h4>+{dashboardData?.users.lastMonthUsers} <span>from last month</span></h4>
											{/* <span>More <img src={blarow} alt="" /></span> */}
										</div>
                  
									</div>
								</div>
								<div className='col-md-3'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Total Work <img src={info} alt="" /> </h6>
											{dashboardData?.works.workPercentageChange<0?<span className={styles.loss}><img src={barrow} alt="" /> {dashboardData?.works.workPercentageChange}%</span>:<span className={styles.profit}><img src={grarow} alt="" /> {dashboardData?.works.workPercentageChange}%</span>}
										
										</div>
										<h5>{dashboardData?.works.totalWorkCount}</h5>
										<div className={styles.revenueBoxFoter}>
											<h4>+{dashboardData?.works.lastMonthWorkCount} <span>from last month</span></h4>
											{/* <span>More <img src={blarow} alt="" /></span> */}
										</div>
                  
									</div>
								</div>
								<div className='col-md-3'>
									<div className={styles.revenueBox}>
										<div className={styles.revenueBoxHead}>
											<h6>Total Posts <img src={info} alt="" /> </h6>
											{dashboardData?.posts.totalPostCount<0?<span className={styles.loss}><img src={barrow} alt="" /> {dashboardData?.posts.totalPostCount}%</span>:<span className={styles.profit}><img src={grarow} alt="" /> {dashboardData?.posts.totalPostCount}%</span>}
										
										</div>
										<h5>{dashboardData?.posts.totalPostCount}</h5>
										<div className={styles.revenueBoxFoter}>
											<h4>+{dashboardData?.posts.lastMonthPostCount} <span>from last month</span></h4>
											{/* <span>More <img src={blarow} alt="" /></span> */}
										</div>
                  
									</div>
								</div>
							</div>
						</div>
						<div className={styles.graphPage}>
							{graphData.revenueGraphData&&<Graph type={"Revenue"} series={graphData.revenueGraphData}/>}
						
						</div>
						<div className={styles.graphPage}>
							{graphData.workReportGraphData&&<Graph type={"Count"} series={graphData.workReportGraphData}/>}
						</div>
						<div className={styles.graphPage}>
							{graphData.postGraphData&&<Graph type={"Post Count"} series={graphData.postGraphData}/>}
						</div>
						<div className={styles.graphPage}>
							{graphData.workGraphData&&<Graph type={"Work Count"} series={graphData.workGraphData}/>}
						</div>
						{/* <div className={styles.graphPage}>
					{graphData.timesheetGraphData&&<Graph type={"Timesheets"} series={graphData.timesheetGraphData}/>}
					</div> */}
						{/* <div className={styles.graphPage}>
						<img src={graph} alt="" />
					</div>
					<div className={styles.graphPage}>
						<img src={graph} alt="" />
					</div> */}
					</>:(
						<div className="d-flex justify-content-center">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}</div>
			</div>
		</div>
	)

    
}

export default Dashboard