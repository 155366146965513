import { createSlice } from "@reduxjs/toolkit";

export const sideBarSlice = createSlice({
	name: "sideBar",
	initialState: {show:false},
	reducers: {
		toggleSidebar: (state, action) => {
			state.show=action.payload.show
		},
	}});

// this is for dispatch
export const { toggleSidebar } = sideBarSlice.actions;

// this is for configureStore
export default sideBarSlice.reducer;