import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";
import { REVIEW } from "../../../utils/review";

export const getAll = (type, paginationPayload, search) => {
	const params={
		...paginationPayload,
		
		search
	}
	if(type!==REVIEW.STATUS.ALL)
	{
		params.status=type
	}
	
	return axios.get(ApiRoutes.REVIEW.getAll, {params:params});
};

export const getSpecifiedReview=(id)=>{
	return axios.get(`${ApiRoutes.REVIEW.getAll}/${id}`)

}
export const changeStatus=(status, id)=>{
	return axios.patch(`${ApiRoutes.REVIEW.getAll}/${id}`, {status})

}
export const removeReview=(id)=>{
	return axios.delete(`${ApiRoutes.REVIEW.getAll}/${id}`)
}