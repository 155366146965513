import React from "react"
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import styles from "../style.module.css"

const ExpandImage = ({ showModal, handleClose}) => {
	const imageLink = useSelector((state) => state.gallery.image);

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
        
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
       Image
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<img src={imageLink}/>
				</Modal.Body>
			</div>
		</Modal>
	)
}

export default ExpandImage