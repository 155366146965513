import { createSlice } from "@reduxjs/toolkit";

export const inquirySlice = createSlice({
	name: "inquiry",
	initialState: { id: "" },
	reducers: {
		editInquiry: (state, action) => {
			state.id = action.payload.id;
		},
	},
});

// this is for dispatch
export const { editInquiry } = inquirySlice.actions;

// this is for configureStore
export default inquirySlice.reducer;
