import { axios } from "../../lib/axios"

import { ApiRoutes } from "../../utils/api_Routes";

export const uploadFiles=(files)=>{
	const formData = new FormData();
	files.forEach((img) => {
		formData.append("files", img)
	})

	return axios.post(ApiRoutes.COMMON.UPLOADFILES, formData, {headers:{"Content-Type":"multipart/form-data"}})
}
export const uploadAudioFiles=(file)=>{
	const formData = new FormData();
	formData.append("file", file);
	formData.append("type", "audio");
	return axios.post(ApiRoutes.COMMON.UPLOADFILES, formData, {headers:{"Content-Type":"multipart/form-data"}})
}

export const downloadExcel=async(api, params)=>{
	const response= await axios.get(api, {params, headers:{"Content-Type":"blob"}, responseType:"arraybuffer"});
        
	const outputFilename = `${Date.now()}.xlsx`;

	// If you want to download file automatically using link attribute.
	const url = URL.createObjectURL(new Blob([response]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", outputFilename);
	document.body.appendChild(link);
	link.click();
	link.remove();
	

}