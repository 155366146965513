import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify"

import { getSpecific, send } from "../api"
import { Layout } from "../../../common/Layout"
import { SendEmailSchema } from "../validations"
import commonstyles from "../../../common/common.module.css"
import { messages } from "../../../utils/messages"
import styles from  "../style.module.css"


const Index = () => {
	const [emailData, setEmailData]=useState()
	const {id}=useParams()
	const [html, setHTML] = useState({__html: ""});
	const [loading, setLoading]=useState(true);

	const EmailFormik = useFormik({
		validationSchema:SendEmailSchema,
		initialValues: {
			email:"",
             
		},
 
		onSubmit: (values) => {
			toast.promise(
				send(id, {type:"email", email:values.email}),
				{
					pending: {
						render() {
							return messages.TEMPLATE.SEND
						},
					},
					success: {
						render() {
							EmailFormik.resetForm();
							return messages.TEMPLATE.SENT
						},
					},
					error: {
						render() {	
							return messages.MASTER.FAILED
						},
					},
				}
			);
       
 
			// uploadFiles(values.file).then((res)=>{
			// 	const {files}=res.data;
			// 	// const payload={link:values.link,  files, description:values.description}
			// 	const payload=values.link ? {link:values.link,  files, description:values.description}: {files, description: values.description}
			// 	toast.promise(
			// 		addAdminPost(payload),
			// 		{
			// 			pending: {
			// 				render() {
			// 					return messages.MASTER.ADDING
			// 				},
			// 			},
			// 			success: {
			// 				render() {
			// 					addPostFormik.resetForm();
			// 					handleClose();
			// 					setUploading(false);
			// 					return messages.MASTER.ADDED
			// 				},
			// 			},
			// 			error: {
			// 				render() {
			// 					setUploading(false);
			// 					return messages.MASTER.FAILED
			// 				},
			// 			},
			// 		}
			// 	);
             
			// }).catch(err=>{
			// 	setUploading(false);
			// 	toast.error(messages.MASTER.IMAGE_UPLOAD_FAILED);
			// })
       
     
		},
	});
	//  const handleAddTemplate=(e)=>{
	//    e.preventDefault();
	//    TemplateFormik.submitForm();
 
	//  }
   
	useEffect(() => {
		getSpecific(id).then((res)=>{
			setEmailData(res.data, "dataaaaa");
			setHTML({__html:res.data.emailBody})
			setLoading(false);
		})
    
  
	}, [])


	return (
		<Layout>
			<Form.Group  className={`form-group ${commonstyles.previewGroup}`} controlId="formName">
				<Form.Control className={commonstyles.previewField} name="email"
					value={EmailFormik.values.email}
								 onChange={EmailFormik.handleChange}
					type="email"
					placeholder="email"
									
				/>
				{EmailFormik.errors.email &&
								EmailFormik.touched.email ? (
						<span className={commonstyles.error}>
							{ EmailFormik.errors.email}
						</span>
					) : null}

				<Button onClick={EmailFormik.handleSubmit}>Send</Button>
			</Form.Group>
			{!loading? <><p>From:{emailData.fromEmail}</p>
				<p>To:{emailData.emailTo}</p>
				<p>CC:{emailData.cc}</p>
				<p>BCC:{emailData.bcc}</p>
				<p>Subject:{emailData.emailSubject}</p>
				<div dangerouslySetInnerHTML={html}/></>:<div
				className={`${styles.spinner} d-flex justify-content-center`}
			>
				<div className="spinner-border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>}
      
		</Layout>
	)
}

export default Index