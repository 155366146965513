import React from "react"
import { useDispatch, useSelector } from "react-redux";

import styles from "./style.module.css";
import logo from "../../assets/images/logo.png";
// import search from "../../assets/images/search.png";
// import env from "../../assets/images/env.png";
// import bell from "../../assets/images/bell.png";
import avatar from "../../assets/images/avatar.png";
import { toggleSidebar } from "../../redux/slices/sideBarSlice";

function Topbar() {
	const dispatch=useDispatch();
	const sideBarStatus = useSelector((state) => state.sideBar.show);

	const handleSideBar=()=>{
		dispatch(toggleSidebar({ show: !sideBarStatus }));


	}
	return (
		<div className={styles.topBar}>
			<div className='row align-items-center'>
				<div className='col-md-6 col-8'>
					<div className={styles.topBarleft}>
						<div className={styles.topLogo}>
							<img src={logo} alt=""/>
							<span onClick={handleSideBar}><i className="fas fa-bars"></i></span>
						</div>
						<div className={styles.formGroup}>
							{/* <input type='text' placeholder='Search'/> */}
							{/* <span><img src={search} alt=""/></span> */}
						</div>
					</div>
				</div>
				<div className='col-md-6 col-4'>
					<div className={styles.topBarright}>
						<div className={styles.notifyIc}>
							{/* <span><img src={env} alt=""/></span>
							<span><img src={bell} alt=""/></span> */}
						</div>
						<div className={styles.userLog}>
							<span><img src={avatar} alt=""/></span>
							<div className={styles.Username}>
								<h6>Admin</h6>
								{/* <p>Business Owner</p> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Topbar