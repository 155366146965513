import { createSlice } from "@reduxjs/toolkit";

export const postSlice = createSlice({
	name: "posts",
	initialState: {id:""},
	reducers: {
		editPost: (state, action) => {
			state.id=action.payload.id
		},
	}});

// this is for dispatch
export const { editPost } = postSlice.actions;

// this is for configureStore
export default postSlice.reducer;