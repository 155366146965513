import React from "react";
import { useState } from "react";
// import eye from "../../assets/images/eye.png";
// import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";

const PasswordFieldWithToggle = ({ field }) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="position-relative">
			<input
				className="form-control"
				type={showPassword ? "text" : "password"}
				{...field}
				style={{ paddingRight: "40px" }}
			/>
			<button
				className="btn btn-link position-absolute end-0"
				type="button"
				onClick={() => setShowPassword(!showPassword)}
				style={{ top: "50%", transform: "translateY(-50%)" }}
			>
				{/* {!showPassword ? <RiEyeCloseLine /> : <RiEyeLine />} */}
				{/* {!showPassword ? eye : eye} */}
			</button>
		</div>
	);
};

export default PasswordFieldWithToggle;
