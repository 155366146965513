import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTableColumns,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Form } from "react-bootstrap";

import searchi from "./../../assets/images/search.png"
import upload from "../../assets/images/upload.png"
import styles from "./style.module.css";
// import upload from "../../assets/images/upload.png";
// import tabpro from "../../assets/images/tabpro.png";
// import email from "../../assets/images/email.png";
// import phone from "../../assets/images/phone.png";
// import cmlogo from "../../assets/images/cmlogo.png";
// import dots from "../../assets/images/dots.png";
// import payone from "../../assets/images/payone.png"
// import paytwo from "../../assets/images/paytwo.png"
// import paythree from "../../assets/images/paythree.png"
// import info from "../../assets/images/info.png";
// import grarow from "../../assets/images/grarow.png";
// import blarow from "../../assets/images/blarow.png";
import {  getAllCountry, getProfessionalByType } from "./api";
import { USER } from "../../utils/user";
import { useInfiniteScroll } from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
import { downloadExcel } from "../../common/api";
import { ApiRoutes } from "../../utils/api_Routes";
import SelectColumns from "./modals/SelectColumns";
import { ADMIN_USER } from "../../utils/admin_user";


function AdminUsers() {

	const [users, setUsers] = useState([]);
	const [tab, setTab] = useState(USER.TYPE.ALL);
	const [activeTab, setActiveTab] = useState(USER.TYPE.ALL);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [showSelectModal, setShowSelectModal] = useState(false);
	const [selectedKey, setSelectedKey]=useState(USER.DEFAULT_SELECTED_COLUMNS)
	const [orderBy, setOrderBy]=useState(USER.COLUMN_KEYS.NAME)
	const [order, setOrder]=useState(-1)
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);
	const [loading, setLoading]=useState(true);
	const [search, setSearch]=useState("");
	const [allCountry, setAllCountry]=useState([]);
	const [country, setCountry]=useState(0);
	const elementRef = useRef(null);

	const handleExcelDownload=()=>{
		downloadExcel(ApiRoutes.USERS.excel, {type:tab})
	}

	useEffect(() => {
		getAllCountry().then((res)=>{
			setAllCountry(res.data)
		})
	
	}, [])
	
	
	const fetchMore = () => {
		setLoading(true);
		getProfessionalByType(ADMIN_USER.TYPE.ADMIN, { page, limit: 10 }, search, selectedKey, orderBy, order, country).then((res) => {
			setLoading(false);
			const { data } = res;
			if (data.length === 0) {
				setHasMore(false);
			} else {
				if(page===1){
					setUsers(data);
				}
				else{
					setUsers((prev) => [...prev, ...data]);
				}
				setPage((prev) => prev + 1);
			}
			setActiveTab(tab);
		});
	};

	useInfiniteScroll(users, elementRef, hasMore, fetchMore);

	const handleUserEditClose = () => {
		setShowEditModal(false);
	};
	const handleUserRemoveClose = () => {
		setShowRemoveModal(false);
		console.log(handleUserEditClose)

	};
	const handleUpdatePasswordClose = () => {
		setShowPasswordModal(false);
		console.log(handleUserRemoveClose)
	};
	const handleSelectClose=()=>{
		setShowSelectModal(false)
		console.log(handleUpdatePasswordClose)
	}

	useEffect(() => {
		setPage(1);
		setHasMore(true);

		setUsers([]);
	}, [tab]);

	useEffect(() => {
		if (showEditModal === false) {
			setPage(1);
			setHasMore(true);

			setUsers([]);
		}
	}, [showEditModal]);
	useEffect(() => {
		if (showSelectModal === false) {
			setPage(1);
			setHasMore(true);

			setUsers([]);
		}
	}, [showSelectModal]);
	useEffect(() => {
		if (showRemoveModal === false) {
			setPage(1);
			setHasMore(true);

			setUsers([]);
		}
	}, [showRemoveModal]);
	useEffect(() => {
		if (showPasswordModal === false) {
			setPage(1);
			setHasMore(true);

			setUsers([]);
		}
	}, [showPasswordModal]);

	const handleActivePanel = (type) => {
		setTab(type);
	};
	const handleSearchChange=(e)=>{
		setSearch(e.target.value)
	}
	const handleReset=()=>{
		setSearch("")
		setPage(1);
		setHasMore(true);

		setUsers([])


	}
	const handleCountry=(value)=>{
		setCountry((value));
		handleSearch();

	}
	const handleActiveOrder=(value)=>{
		setOrderBy(value);
		handleSearch();

	}
	const handleOrder=(value)=>{
		setOrder(Number(value))
		handleSearch()

	}
	const handleSearch=()=>{

		setPage(1);
		setHasMore(true);

		setUsers([])
	}

	return (
		<Layout>
			<h2>Admin Users</h2>
			<div className={styles.OuterTime}>
				{/* <div className="row">
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
						</div> */}
			</div>
			<div className={styles.tableTime}>
				<div className={styles.topTime}>
					<h5>
					Admin Users <i className="fas fa-info-circle"></i>
					</h5>
					<div className={styles.topTimeInner}>
						<form className={styles.formGroup} 
							onSubmit={(e)=>{
								e.preventDefault();
								handleSearch()}
							}>
						
							<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
							<button className={styles.SaerchTag} onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
							<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>
						</form>
					</div>
				</div>
				<div className={`${styles.extrafilter} ${styles.topTime}`}>
					<div className="">
						<button onClick={handleExcelDownload} className={styles.exportButton}>
							<img src={upload}  alt="" /> Export
						</button>
						<button onClick={()=>setShowSelectModal(true)} className={styles.selectButton}>
							<FontAwesomeIcon
								// className={commonStyles.iconmargin}
								icon={faTableColumns}
							/>
							<span>Select Columns</span>
						</button>
					</div>
					<div className={styles.FilterBtn}>
						<Form.Group className="from-group" controlId="type">
							<Form.Label>Order By:</Form.Label>
							<Form.Control
										
								value={orderBy}
								as="select"
								onChange={(e)=>handleActiveOrder(e.target.value)}
	
										
							>
								{
									USER.ORDER_BY_ARRAY.map((item)=><option value={item} key={1}>
										{item}
									</option>)
								}
								
									
							</Form.Control>
						</Form.Group>

						<Form.Group className="from-group" controlId="type">
							<Form.Label>Order:</Form.Label>
							<Form.Control
										
								value={order}
								as="select"
								onChange={(e)=>handleOrder(e.target.value)}
	
										
							>
								
								<option value={1} key={1}>
						Ascending
								</option>
								<option value={-1} key={2}>
						Descending
								</option>
								
									
							</Form.Control>
						</Form.Group>
						<Form.Group className="from-group" controlId="type">
							<Form.Label>Country:</Form.Label>
							<Form.Control
										
								value={country}
								as="select"
								onChange={(e)=>handleCountry(e.target.value)}						
							>
								
								<option value={0} key={0}>
						Select Country
								</option>
								{allCountry.map((country)=><option className={styles.capitalize} value={country._id} key={country.id}>
									<span className={styles.capitalize}>{country.name}</span>
								</option>)}
									
							</Form.Control>
						</Form.Group>
					</div>
				</div>
				
				<div className={styles.Tabstable}>
					<ul>
						<li className={activeTab === USER.TYPE.ALL && styles.active}>
							<Link onClick={() => handleActivePanel(USER.TYPE.ALL)}>
                    All Users
							</Link>
						</li>

						{/* <li className={activeTab === USER.TYPE.VIEWED && styles.active}>
							<Link onClick={() => handleActivePanel(USER.TYPE.VIEWED)}>
                    Viewed
							</Link>
						</li>
						<li className={activeTab === USER.TYPE.SHARED && styles.active}>
							<Link onClick={() => handleActivePanel(USER.TYPE.SHARED)}>
                    Shared
							</Link>
						</li> */}
					</ul>
					{/* <div className={styles.filters}>
								<div className={styles.Formgroup}>
									<label>Arrange : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
								<div className={styles.Formgroup}>
									<label>Select : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
							</div> */}
				</div>
				<div className={styles.Tbalelayout}>
					<table>
						<tr>
							
							{tab === USER.TYPE.ALL ? 
								<>{ selectedKey.map((key, id)=>(
								
									<th key={id} className={styles.capitalize}>{key}</th>
									
								
								))}<th>Action</th></>: (
									<>
										<th>
											{tab === USER.TYPE.VIEWED ? "Viewed By" : "Shared By"}
										</th>
										<th>Count</th>
									</>
								)}
						</tr>
						{users?.map((user, id) => (
							<tr key={id}>
								
								
								{tab === USER.TYPE.ALL ? (
									<>{selectedKey.map((key, id)=>{
										if(key===USER.COLUMN_KEYS.STATUS)
										{
										 return <td key={id}><label className={!user.status && styles.inactive}>
												{user.status ? "Active" : "In Active"}
											</label></td>
										}
										if(key===USER.COLUMN_KEYS.LANGUAGES)
										{
											if(user?.languages?.length)
											{
												const languageProficienciesString = user.languages[0]; 


												const languageProficienciesArray = languageProficienciesString.split(";")
												const commaSeparatedString = languageProficienciesArray.join(", ");
												return  commaSeparatedString


											}
											else{
												return "No Language Specified"
											}
										}
										else if(key===USER.COLUMN_KEYS.NAME)
										{
											return <><img className={styles.pp} src={USER.DEFAULT_PROFILE_PICTURE} alt="" /><span className={styles.capitalize}>{user[key]}</span></>
										}
										else if(key===USER.COLUMN_KEYS.IS_AVAILABLE)
										{
											return <td key={id}>
												<label className={!user.isAvailable && styles.inactive}>
													{user.isAvailable ? "Available" : "Not Available"}
												</label>
													
											</td>
										}
										else if(key===USER.COLUMN_KEYS.IS_VERIFIED)
										{
											return <td key={id} className="text-center">{user.isVerified ? "Verified" : "Not Verified"}</td>
										}
										else if(key===USER.COLUMN_KEYS.WORK_STATUS)
										{
											return <td key={id}>
												<label className={!user.workStatus && styles.inactive}>
													{user.workStatus ? "Available" : "Not Available"}
												</label>
													
											</td>
										}
										else if(key===USER.COLUMN_KEYS.CREATED_AT||key===USER.COLUMN_KEYS.UPDATED_AT||key===USER.COLUMN_KEYS.LAST_LOGIN)
										{
											return <td key={id}>{moment(user[key]).format("DD MMMM YYYY")}</td>

										}
										else{
											if(key!==USER.COLUMN_KEYS.EMAIL&&key!==USER.COLUMN_KEYS.LINKEDIN_LINK)
											{
												return <td key={id} className={styles.capitalize}>{user[key]}</td>
											}
											if(key===USER.COLUMN_KEYS.LINKEDIN_LINK)
											{
												return <td key={id} ><a href={user[key]}>{user[key]}</a></td>
											}
										
											return <td key={id}>{user[key]}</td>
										}
									})}
								
									</>
									
								) : (
									<>
										<td>
											<img className={styles.pp} src={tab === USER.TYPE.VIEWED?user?.userByViewed?.profilePicture:user?.userByShared?.profilePicture}/>
											{tab === USER.TYPE.VIEWED
												? user?.userByViewed?.name
												: user?.userByShared?.name}
										</td>
										<td>{user.count}</td>
									</>
								)}
							</tr>
						))}
					</table>
					{hasMore && (
						<div ref={elementRef}>
							<div
								className={`${styles.spinner} d-flex justify-content-center`}
							>
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		
			<SelectColumns showModal={showSelectModal}
				handleClose={handleSelectClose}
				selectedKeys={selectedKey}
				setSelectedKeys={setSelectedKey}
			/>
		</Layout>
	);
}

export default AdminUsers;
