import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import styles from "../style.module.css";
import { getSpecificInquiry, updateSpecificInquiry } from "../api";
import commonstyles from "../../../common/common.module.css";
import { messages } from "../../../utils/messages";
import { ViewInquirySchema } from "../validations";
import { WEBSITE_INQUIRIES } from "../../../utils/website_inquiries";

const ViewInquiry = ({ showModal, handleClose }) => {
	const [loading, setLoading] = useState(true);
	const inquiryId = useSelector((state) => state.inquiry.id);

	useEffect(() => {
		setLoading(true);
		if (inquiryId !== "") {
			getSpecificInquiry(inquiryId).then((res) => {
				const { question, reply } = res.data;
				inquiryDetailsFormik.setFieldValue(
					WEBSITE_INQUIRIES.FORMIK.QUESTION,
					question
				);
				inquiryDetailsFormik.setFieldValue(
					WEBSITE_INQUIRIES.FORMIK.REPLY,
					reply
				);
				setLoading(false);
			});
		}
	}, [showModal]);

	const inquiryDetailsFormik = useFormik({
		initialValues: {
			question: "",
			reply: "",
		},
		validationSchema: ViewInquirySchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			toast.promise(updateSpecificInquiry(inquiryId, values), {
				pending: {
					render() {
						return messages.WEBSITE_INQUIRY.REPLYING;
					},
				},
				success: {
					render() {
						inquiryDetailsFormik.resetForm();
						handleClose();
						return messages.WEBSITE_INQUIRY.REPLIED;
					},
				},
				error: {
					render() {
						return messages.WEBSITE_INQUIRY.FAILED;
					},
				},
			});
		},
	});
	const handleViewInquiry = (e) => {
		e.preventDefault();
		inquiryDetailsFormik.submitForm();
	};
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Reply Inquiry</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!loading ? (
						<Form onSubmit={handleViewInquiry}>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formQuestion">
									<Form.Label>Question</Form.Label>
									<Form.Control
										type="text"
										placeholder="Question"
										value={inquiryDetailsFormik.values.question}
										name={WEBSITE_INQUIRIES.FORMIK.QUESTION}
										onChange={inquiryDetailsFormik.handleChange}
										disabled
									/>
								</Form.Group>
							</div>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formReply">
									<Form.Label>Reply</Form.Label>
									<Form.Control
										type="text"
										placeholder="Reply"
										value={inquiryDetailsFormik.values.reply}
										name={WEBSITE_INQUIRIES.FORMIK.REPLY}
										onChange={inquiryDetailsFormik.handleChange}
									/>
								</Form.Group>
								{inquiryDetailsFormik.errors.reply &&
                inquiryDetailsFormik.touched.reply ? (
										<span className={commonstyles.error}>
											{inquiryDetailsFormik.errors.reply}
										</span>
									) : null}
							</div>

							{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Choose More Department
                </Button> */}
							<hr />
							{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Add More University
                </Button> */}
							<Button className={`${styles.buttonss} mt-3`} type="submit">
                Reply
							</Button>
						</Form>
					) : (
						<div className="d-flex justify-content-center">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default ViewInquiry;
