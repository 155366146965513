export const BOOST={
	TYPES:{
		CONTRACT:"contract",
		PROFILE:"profile",
		POST:"post",
		JOB:"job",
		ADVERTISEMENT:"advertisement"
	},

	DURATION_TYPE:{
		DAY:"day",
		WEEK:"week",
		MONTH:"month",
		YEAR:"year"
	},
	FORMIK:{
		TYPE:"type",
		DURATION:"duration",
		DURATION_TYPE:"durationType",
		PRICE:"price"

	}
        
}