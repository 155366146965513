import React from "react";
import { Button, Form, Modal } from "react-bootstrap";


import styles from "../style.module.css";


const ChangeStatus = ({ showModal, handleClose, update }) => {

	const handleChangeStatus = () => {
		update();
		handleClose();
	};
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Are You Sure you want to update document</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<>
						{<h5>Are You Sure you want to update document ?</h5>}
						<Form>
							<div className={styles.spacebetween}>
								<Button
									onClick={()=>handleChangeStatus()}
									className={`${styles.statusTrue} mt-3`}
								>
                Yes
								</Button>
								<Button
									onClick={()=>handleClose()}							
									className={`${styles.statusFalse} mt-3`}
								>
               No
								</Button>
								
							</div>
						</Form>
					</>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default ChangeStatus;
