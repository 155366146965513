import React, { useEffect, useState } from "react"
import styles from "./styles/style.module.css"
import moment from "moment"
import loc from "../../../../assets/images/loc.png";
import { Layout } from "../../../../common/Layout";
import { useParams } from "react-router-dom";
import { getSpecifiedWork } from "../../api";

const Work = () => {
	const {id}=useParams()
	const [workDetails, setWorkDetail]=useState()
	const [loading, setLoading]=useState(true)
	useEffect(() => {
		getSpecifiedWork(id).then((res)=>{
			console.log(res.data);
			setWorkDetail(res.data)
			setLoading(false)
		})
    
    
	}, [])
    
	return (
		<Layout>
			{!loading?<div className={styles.FondOpportuniy}>
				<div className="container">
 

					<div className="row mt-4">
        
						<div className="col-md-12">
							<div className={styles.RightOpportunity}>

								<div className={styles.ContarctOuter}>
									<div className={styles.opprBox}>
										<div className={styles.PendingCOnatctList}>
											<h6 className={styles.name_robo}>{workDetails?.occupation?.name}</h6>
											<div className={styles.Loca}>
												<p className={styles.Locate}>
													<img src={loc} alt="" /> {workDetails?.address}
												</p>
											</div>
											<span className={styles.Hrrate}>{workDetails?.workType}</span>
											<div className={styles.PenigSkil}>
												<h5>Job Description</h5>
												<p dangerouslySetInnerHTML={{__html: workDetails?.description}} className={styles.Peras}>
												</p>
											</div>
											<div className={styles.PenigSkilRate22}>
												{/* <h5>Software Licenses</h5> */}
												{/* <div className={styles.SoftWareLicenece}>
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          <ul className={`nav ${styles.TaglinesButton}`}>
                            <li><span className={styles.orangeButtons}>After Effects </span></li>
                            <li><span className={styles.greenButtons}>Figma </span></li>
                            <li><span className={styles.blueButtons}>Adobe </span></li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul className={`nav ${styles.Taglines}`}>
                            <li><span className={styles.greenBOx}></span>Good to have</li>
                            <li><span className={styles.blueBOx}> </span>Mandatory</li>
                          </ul>
                        </div>
                      </div>

                    </div> */}

											</div>
											<div className={styles.bordeerBottom}></div>
											<div className={styles.PenigSkilRate}>
												<h5 className={styles.payrate}>Pay Rates</h5>
												<ul>
													<li>
														<span>Base</span>
														<label>${workDetails?.rates?.baseRate}</label>
													</li>
													<li>
														<span>Overtime</span>
														<label>${workDetails?.rates?.overTimeRate}</label>
													</li>
													<li>
														<span>Sun/Holidays</span>
														<label>${workDetails?.rates?.doubleTimeRate}</label>
													</li>
												</ul>
											</div>

											<div className={styles.bordeerBottom}></div>
											<h5 className={styles.payrate}>Other Details</h5>
											<div className="row">
												<div className="col-lg-7 col-sm-12">
													<div className="row">
														<div className="col-lg-6 col-sm-6">
															<div className={styles.datesFlex}>
																<h6>Start date </h6>
																<p>{moment(workDetails?.times?.startDate).format("DD MMMM YYYY")}</p>
																<i className="far fa-calendar-alt"></i>
															</div>
														</div>

														<div className="col-lg-6 col-sm-6">
															<div className={styles.datesFlex}>
																<h6>Duration</h6>
																<p>{workDetails?.times?.duration} Days </p>
																<i className="far fa-calendar-alt"></i>
															</div>
														</div>

														<div className="col-lg-6 col-sm-6">
															<div className={styles.datesFlex}>
																<h6>End Date </h6>
																<p className="mb-0">{moment(workDetails?.times?.endDate).format("DD MMMM YYYY")}</p>
																<i className="far fa-calendar-alt"></i>
															</div>
														</div>

														<div className="col-lg-6 col-sm-6">
															<div className={styles.datesFlex}>
																<h6>People Needed </h6>
																<p className="mb-0">{workDetails?.times?.noOfPepole}</p>
																<i className="far fa-calendar-alt"></i>
															</div>
														</div>
													</div>
												</div>

												<div className="col-md-12">
													<div className={styles.bordeerBottom}></div>
													<h5 className={styles.payrate}>Expenses</h5>
													<div className="row">
														<div className="col-lg-7 col-sm-12">
															<div className="row">
																<div className="col-lg-6 col-sm-6">
																	<div className={styles.datesFlex2}>
																		<h6>Daily Per Diam </h6>
																		<p className={styles.dollar}>${workDetails?.expense?.dailyPerDiem}</p>
																	</div>
																</div>

																{/* <div className="col-lg-6 col-sm-6">
                              <div className={styles.datesFlex2}>
                                <h6>Duration</h6>
                                <p className={styles.dollar}>$200 </p>
                              </div>
                            </div> */}

															</div>
														</div>
													</div>
												</div>

												<div className="col-md-12">
													<div className={styles.bordeerBottom}></div>
													<h5 className={styles.payrate}>Tools, Machines and equipment</h5>
													<div className={styles.PenigSkil}>
														<ul>
															{
																workDetails?.technicalSkills?.map((skill, id)=><li key={id}>{skill?.manufacturerDetails?.name}</li>)
															}
                          
														</ul>
													</div>
												</div>
												{/* <div className="col-md-12">
                      <div className={styles.bordeerBottom}></div>
                      <h5 className={styles.payrate}>Procedure and Policies</h5>
                      <p className={styles.Peras}>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.
                      </p>
                    </div>

                    <div className="col-md-12">
                      <div className={styles.bordeerBottom}></div>
                      <div className={styles.flexbtns}>
                        <button type="button" className={`btn ${styles.commonbtn} ${styles.applybtn}`}>Apply now ›</button>
                        <button type="button" className={`btn ${styles.commonbtn} ${styles.sharebtn}`}>Share </button>
                      </div>
                    </div> */}
											</div>

										</div>
									</div>
								</div>


								{/* map view */}
            

								{/* end */}
							</div>
						</div>
					</div>
				</div>
			</div>:(
				<div className="d-flex justify-content-center">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			)}
		</Layout>
	)
}

export default Work