import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";
import { WEBSITE_INQUIRIES } from "../../../utils/website_inquiries";

export const getInquiriesByType = (status, pagination, search, selectedKey) => {
	const param =
    status === WEBSITE_INQUIRIES.TYPE.ALL
    	? { ...pagination, search, selectedKeys: selectedKey }
    	: { status, ...pagination, search, selectedKeys: selectedKey };
	return axios.get(ApiRoutes.WEBSITE_INQUIRIES.getAll, { params: param });
};
export const getSpecificInquiry = (id) => {
	return axios.get(`${ApiRoutes.WEBSITE_INQUIRIES.getAll}/${id}`);
};
export const updateSpecificInquiry = (id, { reply }) => {
	return axios.put(`${ApiRoutes.WEBSITE_INQUIRIES.getAll}/${id}`, { reply });
};
export const removeInquiry = (id) => {
	return axios.delete(`${ApiRoutes.WEBSITE_INQUIRIES.getAll}/${id}`);
};
