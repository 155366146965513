import * as Yup from "yup";
import { VALIDATIONS } from "../../../utils/yup_validations";


const AddTypeSchema = Yup.object().shape({
	name: Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
});

const EditMasterSchema = Yup.object().shape({
	name: Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
});

export { EditMasterSchema, AddTypeSchema };
