export const ApiRoutes = {
	COMMON:{
		UPLOADFILES:"/common/upload"
	},
	AUTH:{
		login:"/auth/login"
	},
	USERS:{
		getAll:"/admin/users",
		DELETE:"/admin/users/delete",
		CHANGE_STATUS:"/admin/users/change-status",
		changePassword: "/admin/users/change-password",
		excel:"/admin/users/xl",
		country:"admin/country",
		getProfileDetails:"/admin/users/details"
	},
	WORK:{
		getAll:"/admin/work",
		dashboard:"/admin/work-dashboard",
		excel:"/admin/work/xl"
	},
	MASTER: {
		getAll:"/admin/master",
		dashboard:"/admin/master-count",
		excel: "/admin/master/xl"
	},
	POSTS:{
		getAll:"/admin/post",
		excel: "/admin/post/xl"
	},
	GALLERY:{
		getAll:"/admin/gallery",
		excel: "/admin/gallery/xl"
	},
	WEBSITE_INQUIRIES: {
		getAll: "/admin/websiteinquiries",
		excel: "/admin/websiteinquiries/xl"
	},
	REVIEW:{
		getAll: "/admin/review",
		excel: "/admin/review/xl"
	},
	TIMESHEETS:{
		getAll:"/admin/timesheet",
		excel:"/admin/timesheet/xl",
		SPECIFIC_TIMESHEET:"/admin/timesheet/work",
		WORK_EXCEL:"/admin/timesheet/work/xl"
	},
	EXPENSE:{
		getAll:"/admin/expense",
		SPECIFIC_EXPENSE:"/admin/expense/work",
		excel:"/admin/expense/xl",
		WORK_EXCEL:"/admin/expense/work/xl"


	},
	INVOICE:{
		getAll:"/admin/invoice",
		SPECIFIC_INVOICE:"/admin/invoice/work",
		excel:"/admin/invoice/xl",
		WORK_EXCEL:"/admin/invoice/work/xl"
	},
	TOOLTIPS:{
		get_ALL:"/admin/helptext"
	},
	COMMUNICATION_TEMPALATES:{
		GET_ALL:"/admin/template",
		SEND:"/admin/template/send"
	},
	SUBSCRIPTIONS:{
		GET_ALL:"/admin/subscription",
		GET_ALL_PLANS:"admin/subscription/plan"

	},
	BOOST:{
		GET_ALL:"/admin/boost",
		GET_ALL_PLANS:"admin/boost/plan"

	},
	DASHBOARD:{
		GET:"/admin/dashboard"

	},
	KPI:{
		GET:"/admin/kpi/dashboard"

	},
	DOCUMENTS:{
		GET:"/admin/document"
	},
	TRANSACTION:{
	GET:"/admin/payment/history"
	}
	
};
