import React from "react"
import { Link } from "react-router-dom";

import styles from "./style.module.css";
// import logo from "../../assets/images/logo.png";
// import search from "../../assets/images/search.png";
// import env from "../../assets/images/env.png";
import application from "../../assets/images/application.png";
import pro from "../../assets/images/pro.png";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
import arrowbl from "../../assets/images/arrowbl.png"
// import right from "../../assets/images/right.png"
import payone from "../../assets/images/payone.png"
import paytwo from "../../assets/images/paytwo.png"
import paythree from "../../assets/images/paythree.png"
import eyebl from "../../assets/images/eyebl.png"
import chatbl from "../../assets/images/chatbl.png"
import box from "../../assets/images/box.png"
import link from "../../assets/images/link.png"
import pin from "../../assets/images/pin.png"
import accepttwo from "../../assets/images/accepttwo.png"
import acceptone from "../../assets/images/acceptone.png"
import companyLogo from "../../assets/images/companyLogo.png"
import sort from "../../assets/images/sort.png"

function Profile() {
	return (
		<div className={styles.pageOuter}>
			<Topbar/>
			<div className={styles.pageContainer}>
				<div className={styles.leftContainer}>
					<Sidebar/>
				</div>
				<div className={styles.rightContainer}>
					<h2>My Dashboard / Timesheet/Sims Yani</h2>
					<div className={styles.ProfileTabouter}>
						<div className={styles.bussinesButton}>
							<button>View as Business</button>
							<button>Search Work</button>
							<button>Search Customers</button>
							<button>Wishlist</button>
						</div>
						<div className={styles.profileView}>
							<div className={styles.profileViewLeft}>
								<span><img src={pro} alt="" /></span>
								<div className={styles.profileViewData}>
									<h3>Flavio Coelho <span>Available for Contract Job</span></h3>
									<p>@Robot Programmer : 12 Years Experience</p>
									<ul>
										<li><img src={pin} alt="" /> Florida, United States</li>
										<li><img src={link} alt="" /> examplesite.com</li>
										<li><img src={link} alt="" /> +92 . 0987654321</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={styles.ProfileLonks}>
							<ul>
								<li className='active'><Link to="#">Dashboard</Link></li>
								<li><Link to="#">Summery</Link></li>
								<li><Link to="#">work</Link></li>
								<li><Link to="#">Skills</Link></li>
								<li><Link to="#">Interview Questions</Link></li>
								<li><Link to="#">Occupations</Link></li>
								<li><Link to="#">Industries</Link></li>
								<li><Link to="#">Documents</Link></li>
								<li><Link to="#">Experience</Link></li>
								<li><Link to="#">Others</Link></li>

							</ul>
						</div>
					</div>
					<div className={`${styles.ProfileTime} ${styles.OuterTime}`}>
						<div className='row'>
							<div className='col-md-4'>
								<div className={`${styles.firstTimesheet} ${styles.timeShetet}`}>
									<span><img src={payone} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Timesheet</h6>
										<ul>
											<li>Current Timesheet <label>20</label></li>
											<li className={styles.Due}>Due Timesheet <label>9</label></li>
											<li className={styles.Pending}>Pending <label>9</label></li>
											<li className={styles.Approved}>Approved <label>19</label></li>
										</ul>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className={`${styles.secTimesheet} ${styles.timeShetet}`}>
									<span><img src={paytwo} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Expese</h6>
										<ul>
											<li>Current Timesheet <label>20</label></li>
											<li className={styles.Due}>Due Timesheet <label>9</label></li>
											<li className={styles.Pending}>Pending <label>9</label></li>
											<li className={styles.Approved}>Approved <label>19</label></li>
										</ul>
									</div>
								</div>
							</div>
							<div className='col-md-4'>
								<div className={`${styles.thirdTimesheet} ${styles.timeShetet}`}>
									<span><img src={paythree} alt="" /></span>
									<div className={styles.rightTime}>
										<h6>Invoice</h6>
										<ul>
											<li>Current Timesheet <label>20</label></li>
											<li className={styles.Due}>Due Timesheet <label>9</label></li>
											<li className={styles.Pending}>Pending <label>9</label></li>
											<li className={styles.Approved}>Approved <label>19</label></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.profilePoints}>
						<div className={styles.profilePointsrow}>
							<div className={styles.profilePointscol}>
								<div className={styles.profilePointsinner}>
									<h6>Ongoing Works</h6>
									<h5>45</h5>
									<div className={styles.eyeCtach}>
										<span> <img src={eyebl} alt="" /></span>
										<div className={styles.catchText}>
											<h4> +24% </h4>
											<p>+24% than last month</p>
										</div>
									</div>
                   
								</div>
							</div>
							<div className={`${styles.profilePointscolSec} ${styles.profilePointscol}`}>
								<div className={styles.profilePointsinner}>
									<h6>Offers from Business</h6>
									<h5>45</h5>
									<div className={styles.eyeCtach}>
										<span> <img src={chatbl} alt="" /></span>
										<div className={styles.catchText}>
											<h4> Go to Offers </h4>
										</div>
									</div>
                   
								</div>
							</div>
							<div className={styles.profilePointscol}>
								<div className={styles.profilePointsinner}>
									<h6>Pending Offers</h6>
									<h5>8</h5>
									<div className={styles.eyeCtach}>
										<span> <img src={box} alt="" /></span>
										<div className={styles.catchText}>
										</div>
									</div>
                   
								</div>
							</div>
							<div className={styles.profilePointscol}>
								<div className={styles.profilePointsinner}>
									<h6>Completed Work</h6>
									<h5>24</h5>
									<div className={styles.eyeCtach}>
										<span> <img src={arrowbl} alt="" /></span>
										<div className={styles.catchText}>
										</div>
									</div>
                   
								</div>
							</div>
							<div className={`${styles.profilePointscolSec} ${styles.profilePointscol}`}>
								<div className={styles.profilePointsinner}>
									<h6>Declined Applications</h6>
									<h5>8</h5>
									<div className={styles.eyeCtach}>
										<span> <img src={application} alt="" /></span>
										<div className={styles.catchText}>  
										</div>
									</div>
                   
								</div>
							</div>
						</div>
					</div>

					<div className={styles.ongoingProject}>
            
						<h4>Ongoing works</h4>
						<div className={styles.topHeaderongoing}>
							<div className={styles.leftshowcase}>
								<h6>Showing 6 Ongoing works </h6>
								<h5>Based your preferences</h5>
							</div>
							<div className={styles.rightshowcase}>
								<span><img src={accepttwo} alt="" /> Fulltime</span>
								<span><img src={acceptone} alt="" /> Contcart</span>
								<div className={styles.FormGroup}>
									<span><img src={sort} alt="" /> </span>
									<select>
										<option>Newest</option>
									</select>
                        
								</div>
                       
							</div>
						</div>
						<div className='row'>
							<div className='col-md-6'>
								<div className={styles.prjectCards}>
									<div className={styles.projectCadtop}>
										<div className={styles.projectCadleft}>
											<h6>Johsn Yami</h6>
											<h5>Database Progammer</h5>
											<h4>Rate Per Hour : $44 (Net 60)</h4>
											<ul>
												<li>Date Of Application: 05/22/2023</li>
												<li>Start Date: 05/29/2023</li>
												<li>Est End Date: 03/31/2024</li>
											</ul>
										</div>
										<div className={styles.projectCadright}>
											<img src={companyLogo} alt="" />
											<span>London, England</span>
										</div>
									</div>
									<div className={styles.prjectCardsBtn}>
										<button>Hourly Contract</button>
										<button>View Details</button>
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className={styles.prjectCards}>
									<div className={styles.projectCadtop}>
										<div className={styles.projectCadleft}>
											<h6>Johsn Yami</h6>
											<h5>Database Progammer</h5>
											<h4>Rate Per Hour : $44 (Net 60)</h4>
											<ul>
												<li>Date Of Application: 05/22/2023</li>
												<li>Start Date: 05/29/2023</li>
												<li>Est End Date: 03/31/2024</li>
											</ul>
										</div>
										<div className={styles.projectCadright}>
											<img src={companyLogo} alt="" />
											<span>London, England</span>
										</div>
									</div>
									<div className={styles.prjectCardsBtn}>
										<button>Hourly Contract</button>
										<button>View Details</button>
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className={styles.prjectCards}>
									<div className={styles.projectCadtop}>
										<div className={styles.projectCadleft}>
											<h6>Johsn Yami</h6>
											<h5>Database Progammer</h5>
											<h4>Rate Per Hour : $44 (Net 60)</h4>
											<ul>
												<li>Date Of Application: 05/22/2023</li>
												<li>Start Date: 05/29/2023</li>
												<li>Est End Date: 03/31/2024</li>
											</ul>
										</div>
										<div className={styles.projectCadright}>
											<img src={companyLogo} alt="" />
											<span>London, England</span>
										</div>
									</div>
									<div className={styles.prjectCardsBtn}>
										<button>Hourly Contract</button>
										<button>View Details</button>
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className={styles.prjectCards}>
									<div className={styles.projectCadtop}>
										<div className={styles.projectCadleft}>
											<h6>Johsn Yami</h6>
											<h5>Database Progammer</h5>
											<h4>Rate Per Hour : $44 (Net 60)</h4>
											<ul>
												<li>Date Of Application: 05/22/2023</li>
												<li>Start Date: 05/29/2023</li>
												<li>Est End Date: 03/31/2024</li>
											</ul>
										</div>
										<div className={styles.projectCadright}>
											<img src={companyLogo} alt="" />
											<span>London, England</span>
										</div>
									</div>
									<div className={styles.prjectCardsBtn}>
										<button>Hourly Contract</button>
										<button>View Details</button>
									</div>
								</div>
							</div>
						</div>
           
					</div>
          
				</div>
			</div>
		</div>
	)

    
}

export default Profile