import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";

export const getAll = ( paginationPayload, search, selectedKey, order) => {
	return axios.get(ApiRoutes.COMMUNICATION_TEMPALATES.GET_ALL, {
		params: { ...paginationPayload, search, selectedKeys:selectedKey, orderBy:"createdAt", order},
	});
};
export const getSpecific=(id)=>{
	return axios.get(`${ApiRoutes.COMMUNICATION_TEMPALATES.GET_ALL}/${id}`);
}
export const addTemplate=(payload)=>{
	return axios.post(ApiRoutes.COMMUNICATION_TEMPALATES.GET_ALL, payload)
}
export const editTemplate=(payload, id)=>{
	return axios.put(`${ApiRoutes.COMMUNICATION_TEMPALATES.GET_ALL}/${id}`, payload)
}
export const send=(id, type)=>{
	return axios.post(`${ApiRoutes.COMMUNICATION_TEMPALATES.SEND}/${id}`, {
		...type
	})

}
