export function splitAndCapitalize(str) {
	// Add a space before each capital letter (except the first one)
	let spacedString = str.replace(/([A-Z])/g, " $1").trim();
    
	// Capitalize the first letter of the result
	return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
}

export const quilToolbar=[
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered'}, { list: 'bullet' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['link'],
      ['clean'],
  ]