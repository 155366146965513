export const VALIDATIONS = {
	AUTH:{
		validEmailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		validPasswordRegex:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$!%#*?&]{7,}$/,
	},
	MESSAGES:{
		REQUIRED:"Required",
		passwordsDontMatch: "Passwords don't match",
		FILES:"Only images or videos are allowed",
		LINK:"Invalid Link",
		LINK_OR_FILE: "Atleast one of the fields is required",
	}
};
