import {  useEffect } from "react";

const useInfiniteScroll = (dataArray, ref, hasMore, fetchData) => {
    

	const onIntersection=(entries)=>{
		const firstEntry=entries[0];
		if(firstEntry.isIntersecting&&hasMore){
			fetchData();

		}

	}

	useEffect(()=>{
		const observer=new IntersectionObserver(onIntersection)
		if(observer&&ref.current)
		{
			observer.observe(ref.current)
		}
		return ()=>{
			if(observer)
			{
				observer.disconnect()
			}
		}
	}, [dataArray])

};

export   {useInfiniteScroll};