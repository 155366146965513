import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useParams } from "react-router-dom"
import commonstyles from "../../../common/common.module.css"

import { getSpecific } from "../api"
import { Layout } from "../../../common/Layout"
import styles from  "../style.module.css"


const Index = () => {
	const {id}=useParams()
	const [notificationData, setNotificationData]=useState()
	const [loading, setLoading]=useState(true)
	useEffect(() => {
		getSpecific(id).then((res)=>{
			setNotificationData(res.data)
			//    setEmailData(res.data,"dataaaaa");
			//    setHTML({__html:res.data.emailBody})
			setLoading(false);
		})
       
     
	}, [])
	return (
		<Layout>
			<Form.Group  className={`form-group ${commonstyles.previewGroup}`} controlId="formName">
				<Form.Control className={commonstyles.previewField} name="email"
					// value={TemplateFormik.values.name}
					// onChange={TemplateFormik.handleChange}
					type="text"
					placeholder="email"
									
				/>
				<Button>Send</Button>
			</Form.Group>
			{!loading?<><p>Notification message  with sample data</p>
				<p>{notificationData.pushText}</p>
			</>:<div
				className={`${styles.spinner} d-flex justify-content-center`}
			>
				<div className="spinner-border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>}

		</Layout>
	)
}

export default Index