import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import styles from "../style.module.css";
import { changeStatus} from "../api";
import { messages } from "../../../utils/messages";
import { MASTER } from "../../../utils/master";
import { RoutesUrl } from "../../../utils/RoutesUrl";

const ChangeStatus = ({ showModal, handleClose, status }) => {
	const { id } = useParams();
	const navigate=useNavigate()


	const handleChangeStatus = (newStatus) => {
		toast.promise(changeStatus(newStatus, id), {
			pending: {
				render() {
					return messages.MASTER.CHANGE_STATUS;
				},
			},
			success: {
				render() {
					navigate(RoutesUrl.TIMESHEETS)

					return messages.MASTER.CHANGED_STATUS;
				},
			},
			error: {
				render() {
					return messages.MASTER.CHANGE_STATUS_FAILED;
				},
			},
		});
	};
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Change Status</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<>
						{<h5>Do you want to {status===MASTER.STATUS.REJECTED?"reject":"approved"} the timesheet?</h5>}
						<Form>
							<div className={styles.spacebetween}>
								<Button
									onClick={()=>handleChangeStatus(MASTER.STATUS.APPROVED)}
									className={`${styles.statusTrue} mt-3`}
								>
              Yes
								</Button>
								<Button
									onClick={()=>handleChangeStatus(MASTER.STATUS.REJECTED)}							
									className={`${styles.statusFalse} mt-3`}
								>
              No
								</Button>
								
							</div>
						</Form>
					</>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default ChangeStatus;
