import { createSlice } from "@reduxjs/toolkit";

export const templateSlice = createSlice({
	name: "templates",
	initialState: {id:"", editOrAdd:"add"},
	reducers: {
		editTemplate: (state, action) => {
			state.id=action.payload.id
			state.editOrAdd=action.payload.editOrAdd
		},
	}});

// this is for dispatch
export const { editTemplate } = templateSlice.actions;

// this is for configureStore
export default templateSlice.reducer;