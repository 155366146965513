import { axios } from "../../../lib/axios"
import { ApiRoutes } from "../../../utils/api_Routes"
import { POSTS } from "../../../utils/posts"

export const getAllPosts=(status, pagination, search, selectedKey, order)=>{
	const param=status===POSTS.STATUS.ALL?{...pagination, search, selectedKeys:selectedKey}:{status, ...pagination, search, selectedKeys:selectedKey}
	return axios.get(ApiRoutes.POSTS.getAll, {params:{...param, orderBy:"createdAt", order}})
}
export const changeStatus=(status, id)=>{
	return axios.patch(`${ApiRoutes.POSTS.getAll}/${id}`, {status})

}
export const getSpecifiedPost=(id)=>{
	return axios.get(`${ApiRoutes.POSTS.getAll}/${id}`)

}

export const addAdminPost=(payload)=>{
	return axios.post(`${ApiRoutes.POSTS.getAll}`, payload)
}