export const WORK = {
	STATUS: {
		ALL: "all",
		APPROVED: "approved",
		OPEN: "open",
		ONGOING: "ongoing",
		CLOSED: "closed",
		FILLED: "filled",
		PENDING: "pending",
		REJECTED:"rejected"
	},
};
