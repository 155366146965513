import React, { useEffect, useRef, useState } from "react"
import {  useNavigate } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faEllipsisH, faEye, faTableColumns } from "@fortawesome/free-solid-svg-icons";
import moment from "moment"

import styles from "./style.module.css";
import commonStyles from "../../common/common.module.css";
// import Sidebar from "../../common/Sidebar";
// import Topbar from "../../common/Topbar";
import upload from "../../assets/images/upload.png"
// import tabpro from "../../assets/images/tabpro.png"
import searchi from "./../../assets/images/search.png"
// import email from "../../assets/images/email.png"
// import phone from "../../assets/images/phone.png"
// import cmlogo from "../../assets/images/cmlogo.png"
// import dots from "../../assets/images/dots.png"
import { getAll } from "./api";
import { useInfiniteScroll } from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
import { downloadExcel } from "../../common/api";
import { ApiRoutes } from "../../utils/api_Routes";
import { TIMESHEETS } from "../../utils/timesheets";
import { USER } from "../../utils/user";
import { RoutesUrl } from "../../utils/RoutesUrl";
import SelectColumns from "./modals/SelectTimesheetKeys";

function Timesheet() {
	// const dispatch = useDispatch();
	const [timesheets, setTimesheets] = useState([]);
	const [tab, setTab] = useState(TIMESHEETS.STATUS.ALL);
	const [timesheetsType, setTimesheetsType] = useState(TIMESHEETS.STATUS.ALL);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [showChangeStatusModal, setChangeStatusModal] = useState(false);
	const [showSelectModal, setShowSelectModal] = useState(false);
	const [selectedKey, setSelectedKey]=useState(TIMESHEETS.DEFAULT_SELECTED_COLUMNS)
	const [hasMore, setHasMore]=useState(true);
	const [page, setPage]=useState(1);
	const [order, setOrder]=useState(-1)
	const [loading, setLoading]=useState(true);
	const [search, setSearch]=useState("");
	const navigate=useNavigate();
  
	  const elementRef = useRef(null);
  
	const handleExcelDownload = () => {
		const params=tab===TIMESHEETS.STATUS.ALL?{}:{status:tab}
		downloadExcel(ApiRoutes.TIMESHEETS.excel, params )
	}
  
	const handleOrder=(value)=>{
		setOrder(Number(value))
		handleSearch()

	}
	const handleRemoveClose = () => {
	  setShowRemoveModal(false);
	  console.log(handleChangeStatusClose)
	
	};
	const handleChangeStatusClose = () => {
	  setChangeStatusModal(false);
	  console.log(handleRemoveClose)
	};
  
	const handleSelectClose=()=>{
		setShowSelectModal(false)
	}
	
	  const fetchMore=()=>{
	  setLoading(true)
		  getAll( {page, limit:10}, selectedKey, search, order).then((res)=>{
			setLoading(false)
			  const {data}=res;
			  if(data.length===0)
			  {
				  setHasMore(false)
			  }
			  else{
		  if(page===1){
					setTimesheets(data);
		  }
		  else{
					setTimesheets(prev=>[...prev, ...data])
			
		  }
		  setPage(prev=>prev+1)
			  }
			 
			setTimesheetsType(tab)
		  })
	 
	  }
  
	  useInfiniteScroll(timesheets, elementRef, hasMore, fetchMore);
  
	  useEffect(() => {
		  setPage(1);
		  setHasMore(true);
  
		  setTimesheets([])
	  }, [tab])
  
  
	useEffect(() => {
	  if (showChangeStatusModal === false) {
			if (tab === timesheetsType) {
		  setPage(1);
			  setHasMore(true);
  
			  setTimesheets([])
			} else {
		  setTab(timesheetsType);
			}
	  }
	}, [showChangeStatusModal]);
   
	useEffect(() => {
	  if (showRemoveModal === false) {
			if (tab === timesheetsType) {
		  setPage(1);
			  setHasMore(true);
  
			  setTimesheets([])
			} else {
		  setTab(timesheetsType);
			}
	  }
	}, [showRemoveModal]);
	useEffect(() => {
		if (showSelectModal === false) {
			setPage(1);
			setHasMore(true);

			setTimesheets([])
		}
	}, [showSelectModal]);
  

	const handleSearchChange=(e)=>{
	  setSearch(e.target.value)
	}
	const handleReset=()=>{
	  setSearch("")
	  setPage(1);
		  setHasMore(true);
  
		  setTimesheets([])
  
  
	}
	const handleSearch=()=>{
  
	  setPage(1);
		  setHasMore(true);
  
		  setTimesheets([])
	}
  
	return (

		<Layout>
			<h2>Invoice</h2>
			<div className={styles.OuterTime}>
		 
			</div>
			<div className={styles.tableTime}>
		  <div className={styles.topTime}>
					<h5>
			  Invoice <i className="fas fa-info-circle"></i>
					</h5>
					<div className={styles.topTimeInner}>
			  <form className={styles.formGroup} 
							onSubmit={(e)=>{
								e.preventDefault();
								handleSearch()}
			  }>
							<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
							<button onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
							<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>

			  </form>
						<button onClick={handleExcelDownload} className={styles.exportButton}>
							<img src={upload} alt="" /> Export
			  </button>
			
					</div>
					{/* <div className={styles.filters}>
			  <div className={styles.Formgroup}>
				<label>Arrange : </label>
				<select>
				  <option>Columns</option>
				  <option>Columns</option>
				</select>
			  </div>
			  <div className={styles.Formgroup}>
				<label>Select : </label>
				<select>
				  <option>Columns</option>
				  <option>Columns</option>
				</select>
			  </div>
			</div> */}
					{/* <button>
			  <img src={upload} alt="" /> Import
			</button> */}
					{/* <button>
			  <img src={upload} alt="" /> Excel Format
			</button> */}
					{/* <button
			  onClick={() => setShowAddTypeModal(true)}
			  disabled={loading}
			>
			  <img src={upload} alt="" /> Add{" "}
			  {masterType === "softwarelicences"
				? "Software Licences"
				: masterType.charAt(0).toUpperCase() + masterType.slice(1)}
			</button> */}
		  </div>
		  {/* <div className={styles.Tabstable}>
					<ul>
			  <li
							className={
				  activeTab === TIMESHEETS.STATUS.ALL && styles.active
							}
			  >
							<Link
				  onClick={() => handleActivePanel(TIMESHEETS.STATUS.ALL)}
							>
				  All
							</Link>
			  </li>
			  <li
							className={
				  activeTab === TIMESHEETS.STATUS.APPROVED && styles.active
							}
			  >
							<Link
				  onClick={() => handleActivePanel(TIMESHEETS.STATUS.APPROVED)}
							>
				 Approved
							</Link>
			  </li>
			  <li
							className={
				  activeTab === TIMESHEETS.STATUS.PENDING&& styles.active
							}
			  >
							<Link
				  onClick={() => handleActivePanel(TIMESHEETS.STATUS.PENDING)}
							>
				  Pending
							</Link>
			  </li>
			  <li
							className={
				  activeTab === TIMESHEETS.STATUS.REJECTED && styles.active
							}
			  >
							<Link
				  onClick={() =>
									handleActivePanel(TIMESHEETS.STATUS.REJECTED)
				  }
							>
				  Rejected
							</Link>
			  </li>
					</ul>
		  </div> */}
		   <div className={`${styles.extrafilter} ${styles.topTime}`}>
					<div className="">
							                                                                                                             
						<button onClick={()=>setShowSelectModal(true)} className={styles.selectButton}>
							<FontAwesomeIcon
								// className={commonStyles.iconmargin}
								icon={faTableColumns}
							/>
							<span>Select Columns</span>
						</button>
					</div>
						
					<div className={styles.FilterBtn}>
						

						<Form.Group className="from-group" controlId="type">
							<Form.Label>Order By Date:</Form.Label>
							<Form.Control
										
								value={order}
								as="select"
								onChange={(e)=>handleOrder(e.target.value)}
	
										
							>
								
								<option value={1} key={1}>
						Ascending
								</option>
								<option value={-1} key={2}>
						Descending
								</option>
								
									
							</Form.Control>
						</Form.Group>
					
					</div>
						
				</div>
				<div className={styles.Tbalelayout}>
			  <table>
						<tr>
							{ selectedKey.map((key, id)=>(
								
								<th key={id} className={styles.capitalize}>{key}</th>
							
						
							))}
				  <th>Action</th>
						</tr>
						{timesheets?.map((user, id) => (
							
							<tr key={id}>
								
								
								{tab === USER.TYPE.ALL ? (
									<>{selectedKey.map((key, id)=>{
										if(key===USER.COLUMN_KEYS.STATUS)
										{
										 return <td key={id}><label className={!user.status && styles.inactive}>
												{user.status ? "Active" : "In Active"}
											</label></td>
										}
										
										else if(key===TIMESHEETS.COLUMNS.USERNAME)
										{
											return <><span className={styles.capitalize}>{user[key]}</span></>
										}
										else if(key===TIMESHEETS.COLUMNS.WORKNUMBER)
										{
											return <>#{user[key]}</>

										}
										
										
										else if(key===TIMESHEETS.COLUMNS.WORK_UPDATED_AT||key===TIMESHEETS.COLUMNS.WORK_CREATED_AT||key===USER.COLUMN_KEYS.LAST_LOGIN||key===TIMESHEETS.COLUMNS.WORK_START_DATE||key===TIMESHEETS.COLUMNS.WORK_END_DATE)
										{
											return <td key={id}>{moment(user[key]).format("DD MMMM YYYY")}</td>

										}
										else{
											if(key===TIMESHEETS.COLUMNS.WORK_TYPE)
											{
												return <td key={id}>{user[key].split("_").map((itemm)=>{
													return itemm[0].toUpperCase()+itemm.slice(1)+" "
												})}</td>
											}
											
											
											else{
												return <td key={id}>{user[key]}</td>
											}
										}
									})}
								
									<td>
										<Dropdown className={commonStyles.tddropdown}>
											<Dropdown.Toggle className={styles.dropdownIcon}>
												{/* <a className="me-2"></a> */}
												<FontAwesomeIcon
													// className={commonStyles.iconmargin}
													icon={faEllipsisH}
												/>
											</Dropdown.Toggle>

											<Dropdown.Menu>
											

												<Dropdown.Item
													onClick={() => {
														navigate(`${RoutesUrl.SPECIFIC_INVOICE}`, {state:{userId:user.userId, workId:user.workId, businessId:user._id.businessId}});													setShowRemoveModal(true);
													}}
												>
													<FontAwesomeIcon
														size="xs"
														className={commonStyles.iconmargin}
														icon={faEye}
													/>
                               View More
												</Dropdown.Item>
												
											</Dropdown.Menu>
										</Dropdown>
									</td></>
									
								) : (
									<>
										<td>
											<img className={styles.pp} src={tab === USER.TYPE.VIEWED?user?.userByViewed?.profilePicture:user?.userByShared?.profilePicture}/>
											{tab === USER.TYPE.VIEWED
												? user?.userByViewed?.name
												: user?.userByShared?.name}
										</td>
										<td>{user.count}</td>
									</>
								)}							
							</tr>
						))}	
				
				 
			  </table>
			  {hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
							  <div className="spinner-border" role="status">
								  <span className="sr-only">Loading...</span>
							  </div>
						  </div></div>}
				</div>
			</div>
	
   
			{/* <Remove
	  showModal={showRemoveModal}
	  handleClose={handleRemoveClose}
	  type={reviewType}
	/>
	<ChangeStatus
	  showModal={showChangeStatusModal}
	  handleClose={handleChangeStatusClose}
	/> */}
			<SelectColumns showModal={showSelectModal}
				handleClose={handleSelectClose}
				selectedKeys={selectedKey}
				setSelectedKeys={setSelectedKey}
			/>
		</Layout>

	)

    
}
    
export default Timesheet