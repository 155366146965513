import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import commonstyles from "../../../common/common.module.css";
import styles from "../style.module.css";
import { UpdatePasswordSchema } from "../validations";
import { updateUserPassword } from "../api";
import { messages } from "../../../utils/messages";
import { USER } from "../../../utils/user";

const ChangePassword = ({ showModal, handleClose }) => {
	const userId = useSelector((state) => state.users.id);

	const handleUpdatePassword = (e) => {
		e.preventDefault();
		userDetailsFormik.submitForm();
	};
	const userDetailsFormik = useFormik({
		initialValues: {
			password: "",
			confirmPassword: ""
		},
		validationSchema: UpdatePasswordSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			toast.promise(updateUserPassword(userId, {password: values.password}), {
				pending: {
					render() {
						return messages.USER.UPDATING;
					},
				},
				success: {
					render() {
						userDetailsFormik.resetForm();
						handleClose();

						return messages.USER.UPDATED;
					},
				},
				error: {
					render() {
						return messages.USER.FAILED;
					},
				},
			});
		},
	});
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
				userDetailsFormik.resetForm();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Change Password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleUpdatePassword}>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formPassword">
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Password"
									value={userDetailsFormik.values.password}
									name={USER.FORMIK.PASSWORD}
									onChange={userDetailsFormik.handleChange}
								/>
							</Form.Group>
							{userDetailsFormik.errors.password &&
								userDetailsFormik.touched.password ? (
									<span className={commonstyles.error}>
										{userDetailsFormik.errors.password}
									</span>
								) : null}

							<Form.Group className="form-group" controlId="formCOnfirmPassword">
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Confirm Password"
									value={userDetailsFormik.values.confirmPassword}
									name={USER.FORMIK.CONFIRM_PASSWORD}
									onChange={userDetailsFormik.handleChange}
								/>
							</Form.Group>
							{userDetailsFormik.errors.confirmPassword &&
								userDetailsFormik.touched.confirmPassword ? (
									<span className={commonstyles.error}>
										{userDetailsFormik.errors.confirmPassword}
									</span>
								) : null}
						</div>

						<hr />
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							Change Password
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default ChangePassword;
