import * as Yup from "yup";

import { VALIDATIONS } from "../../../utils/yup_validations";
import { VALIDATION_MESSAGES } from "../../../utils/validationMessages";
import { USER } from "../../../utils/user";

const EditUserSchema = Yup.object().shape({
	name: Yup.string().required(VALIDATIONS.MESSAGES.REQUIRED),
});

const UpdatePasswordSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, VALIDATION_MESSAGES.AUTH.passwordRequired)
		.matches(
			VALIDATIONS.AUTH.validPasswordRegex,
			VALIDATION_MESSAGES.AUTH.passwordValidation
		)
		.required(VALIDATION_MESSAGES.AUTH.required),
	confirmPassword: Yup.string()
		.required(VALIDATION_MESSAGES.AUTH.required)
		.oneOf(
			[Yup.ref(USER.FORMIK.PASSWORD), null],
			VALIDATIONS.MESSAGES.passwordsDontMatch
		),
});
export { EditUserSchema, UpdatePasswordSchema };
