import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Button} from "react-bootstrap"
import "react-phone-number-input/style.css"
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input"

import { getSpecific, send } from "../api"
import { Layout } from "../../../common/Layout"
import commonstyles from "../../../common/common.module.css"
import { messages } from "../../../utils/messages"
import styles from  "../style.module.css"


const Index = () => {
	const {id}=useParams()
	const [smsData, setSmsData]=useState()
	const [loading, setLoading]=useState(true)
	const [value, setValue] = useState("")
	const [error, setError]=useState("");

	useEffect(() => {
		getSpecific(id).then((res)=>{
			setSmsData(res.data)
			//    setEmailData(res.data,"dataaaaa");
			//    setHTML({__html:res.data.emailBody})
			setLoading(false);
		})
       
     
	}, [])
    
	const sendSms=()=>{
		if(isValidPhoneNumber(value))
		{
          
			toast.promise(
				send(id, {type:"sms", phoneNumber:value}),
				{
					pending: {
						render() {
							return messages.TEMPLATE.SEND
						},
					},
					success: {
						render() {
                
							return messages.TEMPLATE.SEND
						},
					},
					error: {
						render() {	
							return messages.MASTER.FAILED
						},
					},
				}
			);
		}
		else{
			console.log(value, "invalid")
			setError("Invalid Phone Number")
		}
	}
	return (
		<Layout>
			<div className={`form-group ${commonstyles.previewGroup}`}>

				<PhoneInput
					className={commonstyles.previewField}
					placeholder="Enter phone number"
					value={value}
					onFocus={()=>setError("")}
					onChange={setValue}/>
      
				<span className={commonstyles.error}>
					{ error}
				</span>
							
				<Button onClick={sendSms}>Send</Button>
			</div>
 
			{!loading?<><p>SMS text with sample data</p>
				<p>{smsData.smsText}</p>
			</>:<div
				className={`${styles.spinner} d-flex justify-content-center`}
			>
				<div className="spinner-border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>}

		</Layout>
	)
}

export default Index