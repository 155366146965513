import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";

import styles from "./style.module.css";
// import search from "../../assets/images/search.png";
import logout from "../../assets/images/logout.png";
import icone from "../../assets/images/icone.png"
import ictwo from "../../assets/images/ictwo.png";
import wrk from "../../assets/images/wrk.png";
import bag from "../../assets/images/bag.png";
import { RoutesUrl } from "../../utils/RoutesUrl";
// import gal from "../../assets/images/gal.png";
import storage from "../../utils/storage";
import usr from "../../assets/images/usr.png";
import gg_website from "../../assets/images/gg_website.png";
// import vector from "../../assets/images/vector.png";
// import { toggleSidebar } from "../../redux/slices/sideBarSlice";

function Sidebar() {
	// const dispatch=useDispatch();
	const location = useLocation();
	const navigate=useNavigate(); 
	const {id}=useParams()

	const handleLogout=()=>{
		storage.clearToken();
		navigate(RoutesUrl.LOGIN)

	}
	// const closeSidebar=()=>{
	// 	dispatch(toggleSidebar({show:true}))
	// }

	return (
		<div className={styles.sidebar}>
			<div className={styles.SearchSide}>
				{/* <input type="text" placeholder="Search" /> */}
				<span>
					{/* <img src={search} alt="" /> */}
				</span>
			</div>
			<h6>MENU</h6>
			<ul>
				<li
					className={location.pathname === RoutesUrl.DASHBOARD && styles.active}
				>
					<Link to={RoutesUrl.DASHBOARD}>
						<img src={icone} alt="" /> Dashboard
					</Link>
				</li>
				<li className={location.pathname === RoutesUrl.KPI && styles.active}>
					<Link to={RoutesUrl.KPI}>
						<img src={ictwo} alt="" /> KPI Dashboard
					</Link>
				</li>
				{/* <li
				
				className={
					(location.pathname === RoutesUrl.DASHBOARD
					// 				||
					//   location.pathname === `${RoutesUrl.TEMPALATES}/${id}`||
					//   location.pathname === `${RoutesUrl.TEMPALATES}/${"all"}`
					) &&
		styles.active
				}
			>
				
				<Link to={RoutesUrl.DASHBOARD}>
					<img src={gg_website} alt="" /> Dashboard
				</Link>
			</li> */}
				<li 
					className={
						location.pathname === RoutesUrl.PROFESSIONALS && styles.active
					}
				>
					<Link to={RoutesUrl.PROFESSIONALS}>
						<img src={usr} alt="" /> Users
					</Link>
				</li>

				<li 
					className={location.pathname === RoutesUrl.ALLWORK && styles.active}
				>
					<Link to={RoutesUrl.ALLWORK}>
						<img src={bag} alt="" /> Work
					</Link>
				</li>
				<li 
					className={
						location.pathname === RoutesUrl.MASTER && styles.active
					}
				>
					<Link to={RoutesUrl.MASTER}>
						<img src={ictwo} alt="" /> Control Center
					</Link>
				</li>
				<li 
					className={
						location.pathname === RoutesUrl.ADMIN_USERS && styles.active
					}
				>
					<Link to={RoutesUrl.ADMIN_USERS}>
						<img src={usr} alt="" /> Admin Users
					</Link>
				</li>
				<li 
					className={
						(location.pathname === RoutesUrl.POSTS||location.pathname === `${RoutesUrl.POSTS}/${id}`) && styles.active
					}
				>
					<Link to={RoutesUrl.POSTS}>
						<img src={wrk} alt="" /> All Posts
					</Link>
				</li>
				<li 
					className={
						(location.pathname === RoutesUrl.ADVERTISEMENTPOSTS||location.pathname === `${RoutesUrl.ADVERTISEMENTPOSTS}/${id}`) && styles.active
					}
				>
					<Link to={RoutesUrl.ADVERTISEMENTPOSTS}>
						<img src={ictwo} alt="" /> Advertisement Posts
					</Link>
				</li>
				{/* <li 
					className={
						(location.pathname === RoutesUrl.GALLERY||location.pathname === `${RoutesUrl.GALLERY}`) && styles.active
					}
				>
					<Link to={RoutesUrl.GALLERY}>
						<img src={gal} alt="" /> Gallery
					</Link>
				</li> */}
				<li
					
					className={
						(location.pathname === RoutesUrl.WEBSITE_INQUIRIES ||
              location.pathname === `${RoutesUrl.WEBSITE_INQUIRIES}/${id}`) &&
            styles.active
					}
				>
					<Link to={RoutesUrl.WEBSITE_INQUIRIES}>
						<img src={gg_website} alt="" /> Website Inquiries
					</Link>
				</li>
				<li
					
					className={
						(location.pathname === RoutesUrl.TIMESHEETS ||
              location.pathname === `${RoutesUrl.TIMESHEETS}/${id}`||
              location.pathname === `${RoutesUrl.TIMESHEETS}/${"all"}`) &&
            styles.active
					}
				>
					<Link to={RoutesUrl.TIMESHEETS}>
						<img src={gg_website} alt="" /> TimeSheets
					</Link>
				</li>
				<li
					
					className={
						(location.pathname === RoutesUrl.INVOICE ||
              location.pathname === `${RoutesUrl.INVOICE}/${id}`||
              location.pathname === `${RoutesUrl.SPECIFIC_INVOICE}`) &&
            styles.active
					}
				>
					<Link to={RoutesUrl.INVOICE}>
						<img src={gg_website} alt="" /> Invoice
					</Link>
				</li>
				<li
					
					className={
						(location.pathname === RoutesUrl.EXPENSE ||
              location.pathname === `${RoutesUrl.EXPENSE}/${id}`||
              location.pathname === `${RoutesUrl.EXPENSE}/${"all"}`) &&
            styles.active
					}
				>
					<Link to={RoutesUrl.EXPENSE}>
						<img src={gg_website} alt="" /> Expense Reports
					</Link>
				</li>
				<li
					
					className={
						(location.pathname === RoutesUrl.TOOLTIPS ||
              location.pathname === `${RoutesUrl.TOOLTIPS}/${id}`||
              location.pathname === `${RoutesUrl.TOOLTIPS}/${"all"}`) &&
            styles.active
					}
				>
					<Link to={RoutesUrl.TOOLTIPS}>
						<img src={gg_website} alt="" /> Tooltips
					</Link>
				</li>
				<li
					
					className={
						(location.pathname === RoutesUrl.TEMPALATES ||
              location.pathname === `${RoutesUrl.TEMPALATES}/${id}`||
              location.pathname === `${RoutesUrl.TEMPALATES}/${"all"}`) &&
            styles.active
					}
				>
					<Link to={RoutesUrl.TEMPALATES}>
						<img src={gg_website} alt="" /> Communications Tempalates
					</Link>
				</li>

				<li
					
					className={
						(location.pathname === RoutesUrl.SUBSCRIPTIONS 
						// 				||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${id}`||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${"all"}`
						) &&
            styles.active
					}
				>
					<Link to={RoutesUrl.SUBSCRIPTIONS}>
						<img src={gg_website} alt="" /> Subscriptions
					</Link>
				</li>
				<li
					
					className={
						(location.pathname === RoutesUrl.BOOST
						// 				||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${id}`||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${"all"}`
						) &&
            styles.active
					}
				>
					
					<Link to={RoutesUrl.BOOST}>
						<img src={gg_website} alt="" /> Boost
					</Link>
				</li>


				<li
					
					className={
						(location.pathname === RoutesUrl.DOCUMENT
						// 				||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${id}`||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${"all"}`
						) &&
            styles.active
					}
				>
					
					<Link to={RoutesUrl.DOCUMENT}>
						<img src={gg_website} alt="" /> Editable Documents
					</Link>
				</li>
				<li
					
					className={
						(location.pathname === RoutesUrl.CMS
						// 				||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${id}`||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${"all"}`
						) &&
            styles.active
					}
				>
					
					<Link to={RoutesUrl.CMS}>
						<img src={gg_website} alt="" /> CMS
					</Link>
				</li>
				<li
					
					className={
						(location.pathname === RoutesUrl.transactions
						// 				||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${id}`||
						//   location.pathname === `${RoutesUrl.TEMPALATES}/${"all"}`
						) &&
            styles.active
					}
				>
					
					<Link to={RoutesUrl.transactions}>
						<img src={gg_website} alt="" /> Transactions
					</Link>
				</li>
				{/* <li
					onClick={closeSidebar}
					className={
						(location.pathname === RoutesUrl.REVIEW||location.pathname === `${RoutesUrl.REVIEW}/${id}`) && styles.active
					}
				>
					<Link to={RoutesUrl.REVIEW}>
						<img src={vector} alt="" /> Reviews
					</Link>
				</li> */}
				{/* <li>
					<Link to="#">
						<img src={ictwo} alt="" /> Timesheet
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Expense Report
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Website Inquiries
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Unsubscribes
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Reviews
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Help Text
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Email Log
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> SMS Log
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Gallery
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Communications
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Affiliates Links
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> Editable forms
					</Link>
				</li>
				<li>
					<Link to="#">
						<img src={ictwo} alt="" /> All Posts
					</Link>
				</li>*/}
				<li onClick={handleLogout}>
					<Link  to="#">
						<img src={logout} alt="" /> Log Out
					</Link>
				</li>
			</ul>
		</div>
	);
}

export default Sidebar;
