export const USER = {
	TYPE: {
		ALL: "all",
		NEWREQUEST: "newrequest",
		VIEWED: "viewed",
		SHARED: "shared",
		SEARCHES: "searches",
		WORK: "work",
		PROFFESIONAL: "professionel",
	},
	FORMIK:{
		NAME:"name",
	   STATUS:"status",
	  ISVERIFIED:"isVerified",
		USERTYPE:"userType",
		PASSWORD: "password",
		CONFIRM_PASSWORD: "confirmPassword"
	},
	DEFAULT_PROFILE_PICTURE:"https://automateamerica.com/app/assets/frontend/images/professional_default.jpg",
	DEFAULT_SELECTED_COLUMNS:["name",
		"email",
		"phoneNumber",
		"status",
		"isAvailable",
	],
	COLUMN_KEYS:{
		NAME:"name",
		EMAIL:"email",
		PHONE_NUMBER:"phoneNumber",
		
		STATUS:"status",
		IS_VERIFIED:"isVerified",
		IS_AVAILABLE:"isAvailable",
		WORK_STATUS:"workStatus",
		LANGUAGES:"languages",
		CREATED_AT:"createdAt",
		UPDATED_AT:"updatedAt",
		LAST_LOGIN:"lastLogin",
		LINKEDIN_LINK:"likendInLink",
		DOB:"dob",
		COUNTRY:"country",
		ADDRESS:"address",

	},
	ORDER_BY_ARRAY:["name", "createdAt", "updatedAt", "lastLogin", "dob"],
	 ALL_COLUMN_keys_ARRAY:[
		"name",
		"email",
		"phoneNumber",
		
		"status",
		"isVerified",
		"isAvailable",
		"workStatus",
		"languages",
		"createdAt",
		"updatedAt",
		"lastLogin",
		"likendInLink",
		"dob",
		"country",
		"address",
	]

};
