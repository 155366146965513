import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import { GRAPH } from "../../utils/graph";


const Area = ({series, type, seriesName}) => {
	const [calculatedSeries, setCalculatedSeries] = useState();
	useEffect(() => {
		const customSeries=[];
		const dataArray=[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		series.map((obj)=>{
			dataArray[obj.monthCode]=obj.count
            
		})
		customSeries.push({name:seriesName, data: dataArray})
		setCalculatedSeries(customSeries)

     
	}, [series])
   
    
	const options={
		chart: {
			height: 500,
			type: "area"
		},
		title: {
			text: type,
			align: "left"
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: "smooth"
		},
		xaxis: {
			categories: GRAPH.MONTH_ARRAY,
		},
		tooltip: {
			x: {
				categories: GRAPH.MONTH_ARRAY,
			},
		},
	}
	return (
		<div>
			<div id="chart">
				{ calculatedSeries&&<ReactApexChart options={options} series={calculatedSeries} type="area" height={500} />}
			</div>
			<div id="html-dist"></div>
		</div>
	)
}

export default Area