import React from "react";
import { Link } from "react-router-dom";

import { Layout } from "../../common/Layout";
import styles from "./style.module.css";
// import searchi from "./../../assets/images/search.png"
import upload from "../../assets/images/upload.png"
function Communication() {
    
	return (
		<Layout>
			<h2>Communication</h2>
			<div className={styles.tableTime}>
				<div className={styles.topTime}>
					<h5>
                    Timesheet <i className="fas fa-info-circle"></i>
					</h5>
					<div className={styles.topTimeInner}>
						<div className={styles.filters}>
							<div className={styles.Formgroup}>
								<label>Arrange : </label>
								<select>
									<option>Columns</option>
									<option>Columns</option>
								</select>
							</div>
							<div className={styles.Formgroup}>
								<label>Select : </label>
								<select>
									<option>Columns</option>
									<option>Columns</option>
								</select>
							</div>
						</div>
						<button className={styles.exportButton}>
							<img src={upload}  alt="" /> Export
						</button>
					</div>
				</div>
				<div className={styles.Tabstable}>
					<ul>
						<li className={styles.active}>
							<Link>
                    All Users
							</Link>
						</li>

						<li className="">
							<Link>
                    Viewed
							</Link>
						</li>
						<li className="">
							<Link>
                    Shared
							</Link>
						</li>
					</ul>
				
				</div>
				<div className={styles.formPage}>
					<form>
						<h4>Email</h4>
						<div className="row">
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Host</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Username</label>
									<input type="text" placeholder="Eg. California"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Password</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Smpt Secure</label>
									<input type="text" placeholder="Eg. California"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Port</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>From</label>
									<input type="text" placeholder="Eg. California"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>CC</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>From Name</label>
									<input type="text" placeholder="Eg. California"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>BCC</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>SendGrid Api Key</label>
									<input type="text" placeholder="Eg. California"/>
								</div>
							</div>
						</div>
						<h4 className="mt-4">Push Notification (Firebase)</h4>
						<div className="row">
							<div className="col-md-12">
								<div className={styles.formGroup}>
									<label>Access Token</label>
									<input type="text" placeholder="AIzaSyAsXCv2Cu0XNWXxtTn4YBgWuYWiLvjx4VI"/>
								</div>
							</div>
						</div>
						<h4 className="mt-4">SMS (Bulk SMS)</h4>
						<div className="row">
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Username</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<label>Password</label>
									<input type="text" placeholder="smtp.sendgrid.net"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<input type="submit" value="Submit"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className={styles.formGroup}>
									<button >Cancel</button>
								</div>
							</div>
						</div>
					</form>
					
				</div>
			</div>
		</Layout>
	)
}

export default Communication