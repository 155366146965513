import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";

export const getProfessionalByType = (type, paginationPayload, search, selectedKeys, orderBy, order, country) => {
	const par={ type, ...paginationPayload, search, selectedKeys, orderBy, order }
	if(country!==0&&country!=="0")
	{
		par.countryId=country
	}
	return axios.get(ApiRoutes.USERS.getAll, {
		params: par,
	});
};
export const getSpecificProfessional = (id) => {
	return axios.get(`${ApiRoutes.USERS.getAll}/${id}`);
};
export const getSpecificUserDetail = (id) => {
	return axios.get(`${ApiRoutes.USERS.getProfileDetails}/${id}`);
};
export const updateSpecificProfessional = (id, payload) => {
	return axios.put(`${ApiRoutes.USERS.getAll}/${id}`, payload);
};
export const removeUser = (id) => {
	return axios.delete(`${ApiRoutes.USERS.getAll}/${id}`);
};
export const updateUserPassword = (id, payload) => {
	return axios.post(`${ApiRoutes.USERS.changePassword}/${id}`, payload);
};
export const getAllCountry=()=>{
	return axios.get(ApiRoutes.USERS.country);
}
export const removeCheckListUser=(userIds, type)=>{
	const body={};
	if(type==="all")
	{
		body.type="all";

	}
	else{
		body.userIds=userIds
	}
	return axios.delete(`${ApiRoutes.USERS.DELETE}`, {data:body});

}
export const changeCheckListStatus=(userIds, type, status)=>{
	const body={status:status};
	if(type==="all")
	{
		body.type="all";

	}
	else{
		body.userIds=userIds
	}
	return axios.put(`${ApiRoutes.USERS.CHANGE_STATUS}`, body);

}