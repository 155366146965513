export const RoutesUrl = {
	ALLWORK: "/all-work",
	SPECIFIC_WORK:"/all-work",
	BUSINESSES: "/businesses",
	DASHBOARD: "/dashboard",
	LOGIN: "/login",
	MASTER: "/master",
	PROFESSIONALS: "/users",
	PROFESSIONAL_DETAILS:"/users/details",
	PROFILE: "/profile",
	TIMESHEETS: "/timesheet",
	INVOICE: "/invoice",
	SPECIFIC_TIMESHEETS: "/timesheet/all",
	SPECIFIC_INVOICE: "/invoice/all",
	ADMIN_USERS:"/adminusers",
	POSTS:"/posts",
	GALLERY:"/gallery",
	ADVERTISEMENTPOSTS:"/adPosts",
	WEBSITE_INQUIRIES: "/webInquiries",
	REVIEW:"/review",
	EXPENSE:"/expense",
	COMMUNICATION:"/communication",
	EDITFORM:"/editform",
	EXPENSE_REPORT_ALL:"/expense/all",
	TOOLTIPS:"/tooltips",
	TEMPALATES:"/tempalates",
	TEMPLATES_EMAIL:"/tempalates/email",
	TEMPLATES_SMS:"/tempalates/sms",
	TEMPLATES_NOTIFICATIONS:"/tempalates/notifications",
	SUBSCRIPTIONS:"/subscriptions",
	SUBSCRIPTION_PLANS:"/subscriptions/plans",
	BOOST:"/boost",
	BOOST_PLANS:"/boost/plans",
	KPI:"/kpi",
	DOCUMENT:"/documents",
	CMS:"/cms",
	transactions:"/transactions"
};
