import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleXmark,
	faCircleCheck,
	faClock,
} from "@fortawesome/free-solid-svg-icons";

import detailstyle from "../detail.module.css";
import styles from "../style.module.css";
// import Topbar from "../../../common/Topbar";
// import Sidebar from "../../../common/Sidebar";
import { getSpecifiedPost } from "../api";
import tabpro from "../../../assets/images/tabpro.png";
import { POSTS } from "../../../utils/posts";
import {Layout} from "../../../common/Layout"


const ViewMore = () => {
	const { id } = useParams();
	const [postData, setPostData] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getSpecifiedPost(id).then((res) => {
			const { data } = res;
			setPostData(data);
			setLoading(false);
			console.log(data);
		});
	}, []);

	return (
		<Layout>
			<div className={detailstyle.uttrePad}>
				<div className={detailstyle.heaingFilter}>
					<h3>Post Details</h3>
				</div>
				{!loading ? (
					<div className={detailstyle.tableData}>
						<div className={detailstyle.addBtn}></div>
						<div className="row">
							<div className="col-md-3">
								<div className={detailstyle.leftProfile}>
									<div className={detailstyle.proImage}>
										<img src={tabpro} width={50} height={50} alt="" />
									</div>
									<h4>{postData?.user.name}</h4>
									<div className={detailstyle.stars}>
										{/* <label>4.5</label> */}
									</div>
									<h6>
                        Status: 
										<span
											className={
												postData?.status === POSTS.STATUS.REJECTED
													? styles.rejected
													: postData?.status === POSTS.STATUS.APPROVED &&
                                styles.approved
											}
										>
											{"  "}
											<FontAwesomeIcon
												icon={
													postData?.status === POSTS.STATUS.REJECTED
														? faCircleXmark
														: postData?.status === POSTS.STATUS.APPROVED
															? faCircleCheck
															: faClock
												}
											/>
											{" "}
											{postData?.status[0].toUpperCase() +
                            postData?.status.slice(1)}
										</span>
									</h6>
								</div>
							</div>
							<div className="col-md-9">
								<div className={detailstyle.rightProfile}>
									<h4>Created At</h4>
									<p>
										{moment(postData?.createdAt).format("MMM DD, YYYY")}
									</p>
									<h4>Link</h4>
									<p><Link to={postData?.link}>{postData?.link}</Link></p>
									<h4>Description</h4>
									<p>{postData?.description}</p>
									<br />
								</div>
							</div>
							<div className="photo-gallery">
								<div className="container">
									<div className="intro">
										<h2 className="text-center">Images</h2>
									</div>
									<div className="row photos">
										{postData?.files.map((src, id) => (
											<div
												key={id}
												className="col-sm-6 col-md-4 col-lg-3 item"
											>
												<a href={src} data-lightbox="photos">
													<img className="img-fluid" src={src} />
												</a>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default ViewMore;
