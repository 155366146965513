export const MASTER = {
	TYPE: {
		OCCUPATIONS:"occupations",
		INDUSTRIES:"industries",
		MANUFACTURERS:"manufacturers",
		SOFTWARE_LICENCES:"softwarelicences",
		APPLICATIONS:"applications",
		REFERRALS:"referrals",
		COMPANIES:"companies",
		DEGREE:"degree",
		SCHOOL:"school",
		SPECIALIZATIONS:"specializations"
	},
	FORMIK: {
		NAME: "name",
		PASSWORD: "password",
		STATUS:"status",
	  ISVERIFIED:"isVerified",
		MASTERTYPE:"masterType",
	},
	STATUS:{
		PENDING:"pending",
		APPROVED:"approved",
		REJECTED:"rejected"

	},
	ALL_COLUMNS_ARRAY:[
		"name",
		"alias",
		"type",
		"status",
		"isDeleted",
		"createdAt",
		"updatedAt",
		"user",
		"work",
		"addedBy",
	],
	DEFAULT_COLUMNS_ARRAY:[
		"name",
		"createdAt",
		"user",
		"work",
		"addedBy",
	],
	COLUMN_KEYS:{
		NAME:"name",
		ALIAS:"alias",
		TYPE:"type",
		STATUS:"status",
		IS_DELETED:"isDeleted",
		CREATED_AT:"createdAt",
		UPDATED_AT:"updatedAt",
		USER:"user",
		WORK:"work",
		ADDED_BY_USER:"addedBy",

	}
	
};