import React, { useEffect, useState } from "react"
import { Layout } from "../../../common/Layout"
import { getSpecificDocument, updateDocuments } from "../api"
import { useParams } from "react-router-dom"
import { Button } from "react-bootstrap"
import ChangeStatus from "../modals/ChangeStatus"
import { toast } from "react-toastify"
import { messages } from "../../../utils/messages"
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import styles from "../style.module.css";
import { quilToolbar } from "../../../utils/common"




const ViewDocument = () => {
 

	const {id}=useParams();
	const [loading,setLoading]=useState(true)
    const modules = {
        toolbar: quilToolbar
      };
	const [showChangeStatusModal, setChangeStatusModal] = useState(false);
    const { quill, quillRef } = useQuill({modules});

	const handleChangeStatusClose = () => {
		setChangeStatusModal(false);
	};

	useEffect(() => {
		getSpecificDocument(id).then((res)=>{
			const {data:{htmlText}}=res;
		  setLoading(false)
          if (quill) {
            quill.clipboard.dangerouslyPasteHTML(htmlText);
          }
        
		})
	}, [quill])
 
    
	const updateDocument=()=>{
		toast.promise(updateDocuments(id, quill.root.innerHTML
), {
			pending: {
				render() {
					return messages.MASTER.EDIT;
				},
			},
			success: {
				render() {

					return messages.MASTER.EDITED;
				},
			},
			error: {
				render() {
					return messages.MASTER.FAILED;
				},
			},
		}); 
	}
   
	return (
		<Layout>
			{/* {html&&<div contentEditable={true} dangerouslySetInnerHTML={html} />} */}
			{/* {html&&<div dangerouslySetInnerHTML={html} ref={ref}>
                
			</div>}<Button onClick={()=>setChangeStatusModal(true)}>Update</Button> */}
 

    {!loading?
	<> 
    <div className={styles.wrapper} >
    <div className={styles.box} >
     <div ref={quillRef} />
   </div></div>
   <Button className={styles.button}  onClick={()=>setChangeStatusModal(true)}>Update</Button>

   </>:(
	<div className="d-flex justify-content-center">
		<div className="spinner-border" role="status">
			<span className="sr-only">Loading...</span>
		</div>
	</div>
)}
     
			<ChangeStatus
				showModal={showChangeStatusModal}
				handleClose={handleChangeStatusClose}
				update={updateDocument}
			/>
            
		</Layout>
	)
}

export default ViewDocument