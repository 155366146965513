import { axios } from "../../../lib/axios"
import { ApiRoutes } from "../../../utils/api_Routes"

export const getAllDocumens=(pagination)=>{
	return axios.get(ApiRoutes.DOCUMENTS.GET, {params:{...pagination, type:["msa", "ssa"]}})
}
export const getSpecificDocument=(id)=>{
	return axios.get(`${ApiRoutes.DOCUMENTS.GET}/${id}`)
}
export const updateDocuments=(id, htmlText)=>{
	const body={htmlText}
	return axios.put(`${ApiRoutes.DOCUMENTS.GET}/${id}`, body)
}