import { axios } from "../../../lib/axios"
import { ApiRoutes } from "../../../utils/api_Routes"
import { POSTS } from "../../../utils/posts"

export const getAllPosts=(status, pagination, search, selectedKey, order)=>{
	const param=status===POSTS.STATUS.ALL?{...pagination, type:POSTS.TYPE.ADVERTISEMENT, search, selectedKeys:selectedKey, orderBy:"createdAt", order}:{status, ...pagination, type:POSTS.TYPE.ADVERTISEMENT, search, selectedKeys:selectedKey, orderBy:"createdAt", order}
	
	return axios.get(ApiRoutes.POSTS.getAll, {params:param})
}
export const changeStatus=(status, id)=>{
	return axios.patch(`${ApiRoutes.POSTS.getAll}/${id}`, {status}, {params:{type:POSTS.TYPE.ADVERTISEMENT}})

}
export const getSpecifiedPost=(id)=>{
	return axios.get(`${ApiRoutes.POSTS.getAll}/${id}`, {params:{type:POSTS.TYPE.ADVERTISEMENT}})

}

export const addAdminPost=(payload)=>{
	return axios.post(`${ApiRoutes.POSTS.getAll}`, {payload, type:POSTS.TYPE.ADVERTISEMENT}, {params:{type:POSTS.TYPE.ADVERTISEMENT}})
}